import * as THREE from "three";
import { loadGLTF, loadTexture, loadHDRCubeTexture, loadRGBE } from "../common/asyncLoaders";
import { getDebugParams, addDebugListener } from "../ui/debugUI";
import { getPerformanceParameters } from "../common/performance";
import { state } from "../common/state";
import { LAYER_HIDEVR } from "../common/constants";

function traverse(
    scene,
    renderer,
    room,
    lightmapRoom,
    lightmapTrees,
    lightmapVines,
    envMap,
    debugParams
) {
    room.traverse((node) => {
        //node.frustumCulled = false;
        if (getPerformanceParameters().envmaps) {
            if (node.material && "envMap" in node.material) {
                if (
                    node.name.indexOf("logo") === 0 ||
                    node.name.indexOf("tv") === 0 ||
                    //node.name.indexOf("quote") === 0 ||
                    node.name.indexOf("walls") === 0 ||
                    node.name.indexOf("fridges") === 0 ||
                    node.name.indexOf("floor") === 0 ||
                    node.name.indexOf("pedestals") === 0
                ) {
                    node.material.envMap = envMap;
                    node.material.envMap.anisotropy = 16;
                    node.material.envMapIntensity = debugParams.envMapIntensity;

                    node.material.needsUpdate = true;
                }
            }
        }
        if (node.name == "quote_0") {
            // node.material = new THREE.MeshBasicMaterial({
            //     map: new THREE.TextureLoader().load(
            //         "https://threejsfundamentals.org/threejs/resources/images/tree-01.png"
            //         //"/static/museum/quote_base.png"
            //     ),
            //     color: 0xff00ff,
            //     alphaTest: 0.5,
            //     toneMapped: false,
            //     transparent: true,
            //     side: THREE.DoubleSide,
            //     name: "xxxxxxxx",
            // });
            node.position.x = -0.02;
            node.position.y = 0.08;
            node.material.emissive.set(0xffffff);
            node.material.emissiveIntensity = 1;
            node.material.toneMapped = false;
            node.material.flatShading = true;
            node.material.color.set(0xffffff);
            node.material.needsUpdate = true;
            node.renderOrder = 2;
        } else if (node.name == "quote_1") {
            node.position.y = 0.08;
            node.position.x -= 0.0004;
            node.renderOrder = 0;
        } else if (node.name === "logo02") {
            node.material.toneMapped = false;
            node.material.needsUpdate = true;
        } else if (node.material && "lightMap" in node.material) {
            if (node.name.indexOf("trees01") === 0 || node.name.indexOf("treecards") === 0) {
                if (node.name.indexOf("trees01") === 0) node.layers.set(LAYER_HIDEVR);
                node.material.lightMap = lightmapTrees;
                node.material.lightMapIntensity = debugParams.lightMapIntensity;
                node.material.needsUpdate = true;
                //node.material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
            } else if (node.name.indexOf("vines") === 0) {
                node.material.lightMap = lightmapVines;
                node.material.lightMapIntensity = debugParams.lightMapIntensity;
                node.material.needsUpdate = true;
            } else if (
                node.name.indexOf("logo02") === 0 ||
                node.name.indexOf("treeline") === 0 ||
                node.name.indexOf("quote") === 0
            ) {
                // no lightmap
                node.material.flatShading = true;
                node.material.toneMapped = false;
                node.material.needsUpdate = true;
            } else {
                node.material.lightMap = lightmapRoom;
                node.material.lightMapIntensity = debugParams.lightMapIntensity;
                if (node.name.indexOf("fridges") === 0) {
                    node.material.lightMapIntensity = debugParams.lightMapIntensityFridges;
                }
                node.material.needsUpdate = true;
            }
        }
    });
}

async function loadRoom(renderer, scene) {
    var gltf = await loadGLTF("/museum/museum.gltf");
    var hdrs = [
        "/envmaps/museum_posx.hdr",
        "/envmaps/museum_negx.hdr",
        "/envmaps/museum_posy.hdr",
        "/envmaps/museum_negy.hdr",
        "/envmaps/museum_posz.hdr",
        "/envmaps/museum_negz.hdr",
    ];
    const [lightmapRoom, lightmapTrees, lightmapVines, envMap] = await Promise.all([
        loadRGBE("/lightmaps/lightmap_museum.hdr"),
        loadRGBE("/lightmaps/lightmap_trees.hdr"),
        loadRGBE("/lightmaps/lightmap_vines.hdr"),
        loadHDRCubeTexture(renderer, hdrs, false),
    ]);

    lightmapRoom.name = "lightmap_room";
    lightmapTrees.name = "lightmap_trees";
    lightmapVines.name = "lightmap_vines";
    envMap.name = "envMap_room";

    lightmapRoom.magFilter = THREE.LinearFilter;
    lightmapRoom.minFilter = THREE.LinearFilter;
    //lightmapRoom.flipY = false;

    lightmapTrees.magFilter = THREE.LinearFilter;
    lightmapTrees.minFilter = THREE.LinearFilter;

    lightmapVines.magFilter = THREE.LinearFilter;
    lightmapVines.minFilter = THREE.LinearFilter;

    //texture.encoding = THREE.sRGBEncoding;
    lightmapRoom.wrapT = THREE.MirroredRepeatWrapping;
    lightmapRoom.wrapS = THREE.MirroredRepeatWrapping;
    lightmapRoom.needsUpdate = true;

    lightmapTrees.wrapT = THREE.MirroredRepeatWrapping;
    lightmapTrees.wrapS = THREE.MirroredRepeatWrapping;
    lightmapTrees.needsUpdate = true;

    lightmapVines.wrapT = THREE.MirroredRepeatWrapping;
    lightmapVines.wrapS = THREE.MirroredRepeatWrapping;
    lightmapVines.needsUpdate = true;

    var room = new THREE.Group();
    room.add(...gltf.scene.children);

    var debugParams = getDebugParams();
    traverse(
        scene,
        renderer,
        room,
        lightmapRoom,
        lightmapTrees,
        lightmapVines,
        envMap,
        debugParams
    );

    addDebugListener("envMaps", "envMapIntensity", 0, 10, 0.01, (params) => {
        traverse(scene, renderer, room, lightmapRoom, lightmapTrees, lightmapVines, envMap, params);
    });
    addDebugListener("lightmaps", "lightMapIntensity", 1, 30, 0.1, (params) => {
        traverse(scene, renderer, room, lightmapRoom, lightmapTrees, lightmapVines, envMap, params);
    });
    addDebugListener("lightmaps", "lightMapIntensityFridges", 1, 30, 0.1, (params) => {
        traverse(scene, renderer, room, lightmapRoom, lightmapTrees, lightmapVines, envMap, params);
    });

    room.name = "room";
    scene.add(room);

    room.matrixAutoUpdate = false;
    room.updateMatrix();

    room.traverse(function (child) {
        if (child.isMesh) {
            if (child.material) {
                if (child.material.map) {
                    child.material.map.anisotropy = 16;
                }

                if (child.material.metalnessMap) {
                    child.material.metalnessMap.anisotropy = 16;
                }

                if (child.material.roughnessMap) {
                    child.material.roughnessMap.anisotropy = 16;
                }

                if (child.material.lightMap) {
                    child.material.lightMap.anisotropy = 16;
                }

                if (child.material.normalMap) {
                    child.material.normalMap.anisotropy = 16;
                }

                if (child.material.emissiveMap) {
                    child.material.emissiveMap.anisotropy = 16;
                }
            }
        }
    });

    return room;
}

export { loadRoom };
