import * as THREE from "three";
import MSDFShader from "three-bmfont-text/shaders/msdf";

function getSourceSansProMaterial(color) {
    return new Promise((resolve, reject) => {
        var textureLoader = new THREE.TextureLoader();

        var url = "/static/fonts/sourcesanspro/sourcesanspro.png";
        textureLoader.load(url, function (texture) {
            texture.anisotropy = 16; // set anisotropy to 16. Improves look of large amounts of text when viewed from angle.
            var mat = new THREE.RawShaderMaterial(
                MSDFShader({
                    map: texture,
                    negate: false,
                    side: THREE.DoubleSide,
                    transparent: true,
                    color: color,
                })
            );
            mat.toneMapped = false;

            resolve(mat);
        });
    });
}

function getSourceSerifProMaterial(color) {
    return new Promise((resolve, reject) => {
        var textureLoader = new THREE.TextureLoader();

        var url = "/static/fonts/sourceserifpro/sourceserifpro.png";
        textureLoader.load(url, function (texture) {
            texture.anisotropy = 16; // set anisotropy to 16. Improves look of large amounts of text when viewed from angle.
            var mat = new THREE.RawShaderMaterial(
                MSDFShader({
                    map: texture,
                    negate: false,
                    side: THREE.DoubleSide,
                    transparent: true,
                    color: color,
                })
            );
            mat.toneMapped = false;

            resolve(mat);
        });
    });
}

function getSansaProMaterial(color) {
    return new Promise((resolve, reject) => {
        var textureLoader = new THREE.TextureLoader();

        var url = "/static/fonts/sansa-pro/sansa-pro.png";
        textureLoader.load(url, function (texture) {
            texture.anisotropy = 16; // set anisotropy to 16. Improves look of large amounts of text when viewed from angle.
            var sansaMaterial = new THREE.RawShaderMaterial(
                MSDFShader({
                    map: texture,
                    negate: false,
                    side: THREE.DoubleSide,
                    transparent: true,
                    color: color,
                })
            );
            sansaMaterial.toneMapped = false;

            resolve(sansaMaterial);
        });
    });
}

function getKarminaMaterial(color) {
    return new Promise((resolve, reject) => {
        var textureLoader = new THREE.TextureLoader();
        var url = "/static/fonts/karmina-italic/Karmina-Italic.png";
        textureLoader.load(url, function (texture) {
            var karminaMaterial = new THREE.RawShaderMaterial(
                MSDFShader({
                    map: texture,
                    negate: false,
                    side: THREE.BackSide,
                    transparent: true,
                    opacity: 1,
                    color: color,
                })
            );
            karminaMaterial.blending = THREE.AdditiveBlending;
            karminaMaterial.name = "fridge_text_material";

            resolve(karminaMaterial);
        });
    });
}

export {
    getKarminaMaterial,
    getSansaProMaterial,
    getSourceSansProMaterial,
    getSourceSerifProMaterial,
};
