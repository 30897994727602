import { changeUIValues } from "../ui/change-ui-values";
import { showNonVrUiElements, closeNonVrWindow, hideNonVrUiElements } from "../ui/ui-animations";
import { moveAndRotateCamera, moveBlob, moveBlobBack } from "../common/animations";
import TWEEN from "@tweenjs/tween.js";
import anime from "animejs/lib/anime.es.js";
import { state } from "../common/state";
import {
    addBlob,
    destroyBlob,
    replaceBlobInRaycastingArray,
    setBlobToRotate,
} from "../model/blobs";
import { hideRoomNav, showRoomNav } from "../common/room-nav";
import { showBlobNav, hideBlobNav } from "../common/blob-nav";
import { hideHamburgerNavButton, showHamburgerNavButton } from "./hamburger-nav";
import { updateUrl } from "../common/url";
import { showVrBlobScreen, hideVrBlobScreen, initializeUiVr } from "../ui/ui-vr";

var isSwitching = false;
var currentPanel;

function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

function restoreUrl() {
    updateUrl(window.museumUrl);
    document.title = state.getOriginalDocumentTitle();
}

function replaceDevUrl(url) {
    if (window.standalone !== true) return url;
    return url.split(window.baseApiUrl).join("");
}

async function closeUI() {
    let blob = state.getCurrentBlob();
    if (blob) {
        restoreUrl();
        state.setCurrentBlob(null);

        hideNonVrUiElements();
        showRoomNav();
        showHamburgerNavButton();
        hideBlobNav();

        state.setUIOpened(false);
        if (state.getVrActive()) {
            hideVrBlobScreen();
            await Promise.all([moveBlobBack(blob)]);
        } else {
            await Promise.all([
                moveBlobBack(blob),
                //hideBlobTitle(),
                closeNonVrWindow(),
            ]);
        }

        // if (isMobile()) {
        //     enableDeviceOrientationControls(true);
        // }

        blob.matrixAutoUpdate = false;
    } else {
        console.error("There is no blob to close.");
    }
}

function findContinentByCountry(countryId) {
    for (var dough of state.getAllDoughs()) {
        if (dough.countryId == countryId) return dough.continent;
    }
}

/**
 * Show a given dough, no matter in what fridge it is. Also moves towards the fridge it is in.
 */
async function fetchDoughAndShow(dough) {
    await replaceDough(dough, dough.continent);

    // set doughs to scroll through
    state.setOpenDoughs(state.getContinentDoughs(dough.continent));
}

async function replaceDough(newDough, continentSlug) {
    var scene = state.getScene();
    var visibleDoughsInFridge = state.getVisibleContinentDoughs(continentSlug);

    if (!visibleDoughsInFridge || visibleDoughsInFridge.length == 0)
        throw new Error("No blobs in fridge");

    // find bottom blob
    var lastDoughVisible = visibleDoughsInFridge[0]; // fridges fill from the bottom up
    var lastBlobVisible = lastDoughVisible.blob;

    // replace it

    var fridge = lastBlobVisible.meta.fridge;
    var newBlobMesh = await addBlob(newDough, fridge);
    var marker = fridge.marker;

    moveAndRotateCamera(marker);

    newBlobMesh.position.copy(lastBlobVisible.position.clone());
    newBlobMesh.updateMatrix();
    scene.add(newBlobMesh);

    replaceBlobInRaycastingArray(lastBlobVisible, newBlobMesh);

    destroyBlob(lastBlobVisible);

    await openBlobAndShowUI(newBlobMesh);

    setBlobToRotate(newBlobMesh);
}

/**
 * Moves toward a fridge, and shows a blob from the country
 */
async function openCountryAndShowUI(countryId) {
    var continentSlug = findContinentByCountry(countryId);
    var allDoughsInFridge = state.getContinentDoughs(continentSlug);
    var newDough = allDoughsInFridge.find((x) => x.countryId == countryId);

    // set open doughs to all doughs of that country
    var countryDoughs = state.getAllDoughs().filter((x) => x.countryId === countryId);
    state.setOpenDoughs(countryDoughs);

    await replaceDough(newDough, continentSlug);
}

/**
 * Show a specified blob. moves towards its fridge.
 * @param {*} blob The blob to show
 */
async function openBlobAndShowUI(blob) {
    if (!blob) {
        throw new Error("Blob cannot be undefined / null");
    }
    var marker = blob.meta.fridge.marker;
    var currentBlob = state.getCurrentBlob();
    if (currentBlob) return;

    const meta = blob.meta.dough;

    if (state.getVrActive()) {
        showVrBlobScreen(blob.meta);

        currentPanel = document.getElementById("panel-vr-ui");
    } else {
        hideRoomNav();
        hideHamburgerNavButton();
        showBlobNav(blob.meta.dough.continent);

        Array.from(document.querySelectorAll(".nonvr-scroller")).forEach((x) => x.scrollTo(0, 0));

        currentPanel = document.getElementById("panel-nonvr");
        changeUIValues(currentPanel, meta);
        currentPanel.classList.add("panel-nonvr--active");
        currentPanel.classList.add("panel-nonvr--visible");

        showNonVrUiElements();

        // change browser url and title
        var url = replaceDevUrl(meta.url);
        updateUrl(url, meta.title);
        document.title = meta.title;

        moveAndRotateCamera(marker);
    }
    console.log("after split");

    blob.matrixAutoUpdate = true;
    await moveBlob(blob, marker, true);

    console.log("after move blob");

    state.setCurrentBlob(blob);
    console.log("SET current  blob", blob);

    state.setUIOpened(true);

    return blob;
}

function nextBlob() {
    switchBlobs((doughs, currentIndex) => {
        // calculate next index
        let newIndex = currentIndex + 1;
        if (newIndex == doughs.length) {
            newIndex = 0;
        }

        return newIndex;
    }, false);
}

function previousBlob() {
    switchBlobs((doughs, currentIndex) => {
        // calculate previous index
        let newIndex = currentIndex - 1;
        if (newIndex < 0) {
            newIndex = doughs.length - 1;
        }

        return newIndex;
    }, true);
}

function switchBlobs(calculateNewIndex, isReverse) {
    var scene = state.getScene();
    var currentBlob = state.getCurrentBlob();
    if (currentBlob && !isSwitching) {
        isSwitching = true;
        let currentMeta = currentBlob.meta;

        var doughs = state.getOpenDoughs();

        let currentIndex = doughs.indexOf(currentMeta.dough);

        let newIndex = calculateNewIndex(doughs, currentIndex);
        let newMeta = doughs[newIndex];

        const panel = document.getElementById("panel-nonvr");
        Array.from(document.querySelectorAll(".nonvr-scroller")).forEach((x) => x.scrollTo(0, 0));
        changeUIValues(panel, newMeta);

        let pos = currentBlob.position;
        addBlob(newMeta, currentMeta.fridge).then((newBlobMesh) => {
            newBlobMesh.position.set(pos.x, pos.y, pos.z);
            newBlobMesh.matrixAutoUpdate = true; // since we immediately start animating it
            newBlobMesh.updateMatrix();
            scene.add(newBlobMesh);

            newBlobMesh.meta.originalPosition = currentMeta.originalPosition.clone();

            var startPos = isReverse
                ? newBlobMesh.position.z + 0.45
                : newBlobMesh.position.z - 0.45;
            var endPos = pos.z;

            newBlobMesh.position.z = startPos;
            new TWEEN.Tween(newBlobMesh.position)
                .to({
                    z: endPos,
                })
                .easing(TWEEN.Easing.Quadratic.InOut)
                .duration(1500)
                .start();

            var startPos = currentBlob.position.z;
            var endPos = isReverse ? currentBlob.position.z - 0.45 : currentBlob.position.z + 0.45;

            setBlobToRotate(newBlobMesh);
            new TWEEN.Tween(currentBlob.position)
                .to({
                    z: endPos,
                })
                .easing(TWEEN.Easing.Quadratic.InOut)
                .duration(1500)
                .onComplete(function () {
                    replaceBlobInRaycastingArray(currentBlob, newBlobMesh);

                    // change browser url and title
                    var url = replaceDevUrl(newMeta.url);
                    updateUrl(url, newMeta.title);
                    document.title = newMeta.title;

                    // remove original blob
                    destroyBlob(currentBlob);

                    //setBlobToRotate(newBlobMesh);

                    // should happen last, this replacement
                    state.setCurrentBlob(newBlobMesh);
                    isSwitching = false;
                })
                .start();
        });
    }
}

export {
    openBlobAndShowUI,
    openCountryAndShowUI,
    closeUI,
    nextBlob,
    previousBlob,
    switchBlobs,
    fetchDoughAndShow,
};
