import * as THREE from "three";
import { LAYER_HIDEVR } from "./constants";

var scene;
var geometry = new THREE.BufferGeometry();
var parameters;
var uniforms;
var geometry;
var particleSystem;
var particles = 400;
var materials = [];
var vertices = [];
var meshes = [];
var sprites = [];
var spriteBase = null;

function initializeParticles(sceneParam) {
    scene = sceneParam;

    // uniforms = {
    //     pointTexture: {
    //         value: new THREE.TextureLoader().load(
    //             "https://s3.eu-west-3.amazonaws.com/puratos-sourdough-museum/files_staging/spark.png"
    //         ),
    //     },
    // };

    // var vertexSource = `

    //     attribute float size;
    //     attribute float vertexpos;
    //     varying vec3 vColor;

    //     void main() {

    //         vColor = color;

    //         vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );

    //         gl_PointSize = size * ( 300.0 / -mvPosition.z );
    //         gl_Position = projectionMatrix * mvPosition;

    //     }
    // `;

    // var fragmentSource = `

    //     uniform sampler2D pointTexture;
    //     varying vec3 vColor;

    //     void main() {

    //         gl_FragColor = vec4( vColor, 1.0 );

    //         gl_FragColor = gl_FragColor * texture2D( pointTexture, gl_PointCoord );

    //     }

    // `;
    // var shaderMaterial = new THREE.ShaderMaterial({
    //     uniforms: uniforms,
    //     vertexShader: vertexSource,
    //     fragmentShader: fragmentSource,

    //     blending: THREE.AdditiveBlending,
    //     depthTest: false,
    //     transparent: true,
    //     vertexColors: true,
    // });

    spriteBase = new THREE.Object3D();
    var spriteTexture = new THREE.TextureLoader().load(
        "https://s3.eu-west-3.amazonaws.com/puratos-sourdough-museum/files_staging/spark.png"
    );
    var sprite = null;
    var scaleFactor = 0;

    for (var i = 0; i < particles; i++) {
        sprite = new THREE.Sprite(
            new THREE.SpriteMaterial({
                map: spriteTexture,
                transparent: true,
                opacity: 0.01 + Math.random() * 0.8,
                blending: THREE.AdditiveBlending,
                alphaTest: 0.5,
            })
        );

        sprite.position.x = -10 + Math.random() * 20;
        sprite.position.y = 0.6 + Math.random() * 10;
        sprite.position.z = -10 + Math.random() * 20;
        sprite.layers.set(LAYER_HIDEVR);

        scaleFactor = Math.random() * 0.05;
        sprite.scale.set(0.025 + scaleFactor, 0.025 + scaleFactor, 1);

        sprite.userData = {
            speedX: (-0.5 + Math.random() * 1.0) * Math.random() * 0.01,
            speedY: (-0.5 + Math.random() * 1.0) * Math.random() * 0.01,
            speedZ: (-0.5 + Math.random() * 1.0) * Math.random() * 0.01,
        };

        sprites.push(sprite);

        spriteBase.add(sprite);
    }

    scene.add(spriteBase);

    /*

    var radius = 200;

    geometry = new THREE.BufferGeometry();

    var positions = [];
    var vertexpos = [];
    var colors = [];
    var sizes = [];
    var speed = [];

    var color = new THREE.Color();

    for ( var i = 0; i < particles; i ++ ) {

        positions.push( ( Math.random() * 2 - 1 ) * radius );
        positions.push( ( Math.random() * 2 - 1 ) * radius );
        positions.push( ( Math.random() * 2 - 1 ) * radius );

        color.setHSL( i / particles, 1.0, 0.5 );

        colors.push( color.r, color.g, color.b );

        sizes.push( 20 );

        speed.push( -0.5 + Math.random() * 1.0 );
        speed.push( -0.5 + Math.random() * 1.0 );
        speed.push( -0.5 + Math.random() * 1.0 );

    }

    vertexpos = positions;

    geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( positions, 3 ) );
    geometry.setAttribute( 'vertexpos', new THREE.Float32BufferAttribute( vertexpos, 3 ) );
    geometry.setAttribute( 'color', new THREE.Float32BufferAttribute( colors, 3 ) );
    geometry.setAttribute( 'size', new THREE.Float32BufferAttribute( sizes, 1 ).setUsage( THREE.DynamicDrawUsage ) );
    geometry.setAttribute( 'speed', new THREE.Float32BufferAttribute( speed, 3 ).setUsage( THREE.DynamicDrawUsage ) );

    particleSystem = new THREE.Points( geometry, shaderMaterial );

    scene.add( particleSystem );
*/
}

function onParticlesRenderTick(camera) {
    spriteBase.rotation.y += 0.005;

    for (var i = 0; i < sprites.length; i += 1) {
        sprites[i].position.x += sprites[i].userData.speedX;
        sprites[i].position.y += sprites[i].userData.speedY;
        sprites[i].position.z += sprites[i].userData.speedZ;

        if (sprites[i].position.x > 4.5) sprites[i].position.x = -4.495;
        if (sprites[i].position.x < -4.5) sprites[i].position.x = 4.495;

        if (sprites[i].position.y > 4.5) sprites[i].position.y = 0.4;
        if (sprites[i].position.y < 0.4) sprites[i].position.y = 4.49;

        if (sprites[i].position.z > 6.5) sprites[i].position.z = -6.495;
        if (sprites[i].position.z < -6.5) sprites[i].position.z = 6.495;
    } /*;
          geometry.attributes.position.array[i+1] += geometry.attributes.speed.array[i+1];
          geometry.attributes.position.array[i+2] += geometry.attributes.speed.array[i+2]; 

          if( geometry.attributes.position.array[i] > 150 ) geometry.attributes.position.array[i]  = geometry.attributes.position.array[i] - 299;
          if( geometry.attributes.position.array[i] < -150 ) geometry.attributes.position.array[i]  = geometry.attributes.position.array[i] + 299;
          
          if( geometry.attributes.position.array[i+1] > 150 ) geometry.attributes.position.array[i+1]  = geometry.attributes.position.array[i+1] - 299;
          if( geometry.attributes.position.array[i+1] < -150 ) geometry.attributes.position.array[i+1]  = geometry.attributes.position.array[i+1] + 299;

          if( geometry.attributes.position.array[i+2] > 150 ) geometry.attributes.position.array[i+2]  = geometry.attributes.position.array[i+2] - 299;
          if( geometry.attributes.position.array[i+2] < -150 ) geometry.attributes.position.array[i+2]  = geometry.attributes.position.array[i+2] + 299;          
          //if( geometry.attributes.position.array[i+2] > 10 ) geometry.attributes.position.array[i+2] * -1;

          //if( i==0) console.log( "yippieh! " + geometry.attributes.vertexpos.array.length)
    }

    geometry.attributes.position.needsUpdate = true;
    */
    /*
    var time = Date.now() * 0.005;


    //var attributes = geometry.attributes;


    for ( var i = 0; i < geometry.attributes.position.array.length; i+= 3 ) {

          geometry.attributes.poersition.array[i]   += geometry.attributes.speed.array[i] /* ( 1 + Math.sin( 0.1 * i + time ) )*/
}

export { initializeParticles, onParticlesRenderTick };
