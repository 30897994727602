import { isMobile } from "./device";

export default function () {
    var videoLinks = Array.from(document.querySelectorAll("section.intro .video-link"));

    videoLinks.forEach(function (el, idx) {
        let vid = el.querySelector(".video video");

        el.addEventListener("mouseover", function () {
            if (vid.paused) {
                var playPromise = vid.play();
                if (playPromise !== undefined) {
                    console.log("playing");
                }
            }
        });
        el.addEventListener("mouseleave", function () {
            if (!vid.paused) vid.pause();
        });
    });

    if (isMobile()) {
        var videoVideo = document.getElementById("video-video");
        if (videoVideo) {
            videoVideo.play();
        }
        var videoMuseum = document.getElementById("video-museum");
        if (videoMuseum) {
            videoMuseum.play();
        }
    }
}
