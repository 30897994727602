import * as THREE from "three";
import { WebGLRenderer, PerspectiveCamera } from "three";
import { DeviceOrientationControls } from "three/examples/jsm/controls/DeviceOrientationControls.js";
import { state } from "./common/state";
import { isMobile } from "./common/device";
import { showGrabbingCursor, restoreCursor } from "./common/cursors";

var PI_2 = Math.PI / 2;

var pitchObject,
    poseMatrix = new THREE.Matrix4(),
    yawObject,
    previousMouseEvent,
    mouseDown,
    touchStart,
    touchStarted,
    cam,
    hasMoved = false,
    deltaYaw,
    magicWindowControls,
    magicWindowObject,
    magicWindowAbsoluteEuler,
    previousMagicWindowYaw,
    previousHMDPosition,
    savedRotation,
    savedPosition,
    position,
    rotation,
    deltaRotation,
    hmdQuaternion,
    savedPose,
    magicWindowDeltaEuler;

function setupMouseControls() {
    mouseDown = false;
    pitchObject = new THREE.Object3D();
    yawObject = new THREE.Object3D();
    yawObject.position.y = 10;
    yawObject.add(pitchObject);
}

function getCurrentCameraRotation() {
    return {
        yawObject: yawObject,
        pitchObject: pitchObject,
    };
}

/**
 * @param {WebGLRenderer} renderer
 * @param {PerspectiveCamera} camera
 */
function initLookControls(renderer, camera) {
    if (!renderer) throw new Error("Renderer should be provided in lookControls");
    if (!camera) throw new Error("Camera should be provided in lookControls");
    cam = camera;
    camera.rotation.order = "YXZ"; // https://discourse.threejs.org/t/camera-rotation-for-first-person-controls-not-correctly/5476/4

    deltaYaw = 0;
    previousHMDPosition = new THREE.Vector3();
    hmdQuaternion = new THREE.Quaternion();
    magicWindowAbsoluteEuler = new THREE.Euler();
    magicWindowDeltaEuler = new THREE.Euler();
    position = new THREE.Vector3();
    // To save / restore camera pose
    savedRotation = new THREE.Vector3();
    savedPosition = new THREE.Vector3();
    magicWindowObject = new THREE.Object3D();
    rotation = {};
    deltaRotation = {};
    savedPose = null;

    var domEl = renderer.domElement;

    setupMouseControls();
    domEl.addEventListener("mousedown", onMouseDown);
    domEl.addEventListener("mousemove", onMouseMove);
    domEl.addEventListener("mouseup", onMouseUp);
    domEl.addEventListener("touchstart", onTouchStart);
    domEl.addEventListener("touchend", onTouchEnd);
    domEl.addEventListener("touchmove", onTouchMove);

    //setupMagicWindowControls();
}

function enableDeviceOrientationControls(enabled) {
    if (typeof DeviceOrientationEvent !== "undefined") {
        if (!magicWindowControls)
            magicWindowControls = new DeviceOrientationControls(magicWindowObject);
        magicWindowControls.enabled = enabled;
    }
}

function setupMagicWindowControls() {
    var magicWindowControls;

    // Only on mobile devices and only enabled if DeviceOrientation permission has been granted.
    if (isMobile()) {
        if (
            location.hostname !== "localhost" &&
            location.hostname !== "127.0.0.1" &&
            location.protocol === "http:"
        ) {
            window.alert(
                "Access this site over HTTPS to enter VR mode and grant access to the device sensors."
            );
            return;
        }
    }
}

/**
 * Translate touch move to Y-axis rotation.
 */

function onTouchMove(evt) {
    var direction;
    var deltaY, deltaX;

    if (!touchStarted) {
        return;
    }

    deltaY = (2 * Math.PI * (evt.touches[0].pageX - touchStart.x)) / window.innerWidth;
    deltaX = (2 * Math.PI * (evt.touches[0].pageY - touchStart.y)) / window.innerHeight;

    direction = -1;
    yawObject.rotation.y -= deltaY * 0.4 * direction;
    pitchObject.rotation.x += deltaX * 0.4 * -direction;
    pitchObject.rotation.x = Math.max(-PI_2, Math.min(PI_2, pitchObject.rotation.x));
    touchStart = {
        x: evt.touches[0].pageX,
        y: evt.touches[0].pageY,
    };
}

/**
 * Register touch down to detect touch drag.
 */
function onTouchStart(evt) {
    //if (evt.touches.length !== 1 || !this.data.touchEnabled || this.el.sceneEl.is("vr-mode")) {
    if (evt.touches.length !== 1 || state.getUIOpened()) {
        return;
    }
    touchStart = {
        x: evt.touches[0].pageX,
        y: evt.touches[0].pageY,
    };
    touchStarted = true;
}

/**
 * Register touch end to detect release of touch drag.
 */
function onTouchEnd() {
    touchStarted = false;
}

/**
 * Translate mouse drag into rotation.
 *
 * Dragging up and down rotates the camera around the X-axis (yaw).
 * Dragging left and right rotates the camera around the Y-axis (pitch).
 */
function onMouseMove(event) {
    var direction;
    var movementX;
    var movementY;

    // Not dragging or not enabled.
    if (!mouseDown) {
        return;
    }

    movementX = event.screenX - previousMouseEvent.screenX;
    movementY = event.screenY - previousMouseEvent.screenY;

    previousMouseEvent = event;

    // Calculate rotation.
    direction = 1; //this.data.reverseMouseDrag ? 1 : -1;
    yawObject.rotation.y += movementX * 0.002 * direction;
    pitchObject.rotation.x += movementY * 0.002 * direction;
    pitchObject.rotation.x = Math.max(-PI_2, Math.min(PI_2, pitchObject.rotation.x));

    if (movementX != 0 || movementY != 0) hasMoved = true;
}

/**
 * Register mouse down to detect mouse drag.
 */
function onMouseDown(evt) {
    // if (!this.data.enabled || (sceneEl.is("vr-mode") && sceneEl.checkHeadsetConnected())) {
    //     return;
    // }
    // Handle only primary button.

    if (evt.button !== 0 || state.getCurrentBlob() || state.getUIOpened()) {
        return;
    }

    mouseDown = true;
    hasMoved = false;
    previousMouseEvent = evt;
    showGrabbingCursor();

    // if (this.data.pointerLockEnabled && !this.pointerLocked) {
    //     if (canvasEl.requestPointerLock) {
    //         canvasEl.requestPointerLock();
    //     } else if (canvasEl.mozRequestPointerLock) {
    //         canvasEl.mozRequestPointerLock();
    //     }
    // }
}

function onMouseUp() {
    mouseDown = false;

    if (hasMoved) window.lastLookedAround = performance.now();

    restoreCursor();
}

function updateMagicWindowOrientation() {
    // Calculate magic window HMD quaternion.
    if (magicWindowControls && magicWindowControls.enabled) {
        magicWindowControls.update();
        magicWindowAbsoluteEuler.setFromQuaternion(magicWindowObject.quaternion, "YXZ");
        if (!previousMagicWindowYaw && magicWindowAbsoluteEuler.y !== 0) {
            previousMagicWindowYaw = magicWindowAbsoluteEuler.y;
        }
        if (previousMagicWindowYaw) {
            magicWindowDeltaEuler.x = magicWindowAbsoluteEuler.x;
            magicWindowDeltaEuler.y += magicWindowAbsoluteEuler.y - previousMagicWindowYaw;
            magicWindowDeltaEuler.z = magicWindowAbsoluteEuler.z;
            previousMagicWindowYaw = magicWindowAbsoluteEuler.y;
        }
    }
}

/**
 * Update orientation for mobile, mouse drag, and headset.
 * Mouse-drag only enabled if HMD is not active.
 */
function updateOrientation() {
    var pose;

    // In VR mode, THREE is in charge of updating the camera pose.
    // if (sceneEl.is("vr-mode") && sceneEl.checkHeadsetConnected()) {
    //     // With WebXR THREE applies headset pose to the object3D matrixWorld internally.
    //     // Reflect values back on position, rotation, scale for getAttribute to return the expected values.
    //     if (sceneEl.hasWebXR) {
    //         pose = sceneEl.renderer.xr.getCameraPose();
    //         if (pose) {
    //             poseMatrix.elements = pose.transform.matrix;
    //             poseMatrix.decompose(object3D.position, object3D.rotation, object3D.scale);
    //         }
    //     }
    //     return;
    // }

    //updateMagicWindowOrientation();

    // On mobile, do camera rotation with touch events and sensors.
    if (pitchObject && yawObject) {
        cam.rotation.x = magicWindowDeltaEuler.x + pitchObject.rotation.x;
        cam.rotation.y = magicWindowDeltaEuler.y + yawObject.rotation.y;
        cam.rotation.z = magicWindowDeltaEuler.z;
    }
}

function isLookingAround() {
    return mouseDown;
}

export {
    initLookControls,
    updateOrientation,
    getCurrentCameraRotation,
    isLookingAround,
    enableDeviceOrientationControls,
};
