var currentBlob = null;
var uiOpened = false;
var vrActive = false;
const visibleContinentDoughs = {};
var openDoughs = [];
const continentDoughs = {};
var doughsPerFlourtype = null;
var originalDocumentTitle;
var camera = null,
    dolly = null,
    scene = null,
    allDoughs = null,
    allArtifacts = null,
    htmlScreen = null,
    controller1 = null,
    controller2 = null,
    controllerModel1 = null,
    controllerModel2 = null,
    renderer = null;

const state = {
    setUIOpened: (value) => {
        // always on next tick
        setTimeout(() => {
            uiOpened = value;
        });
    },
    /** Indicates any UI window in an open state. To prevent other UI's opening on top of that. */
    getUIOpened: () => {
        return uiOpened;
    },
    setOriginalDocumentTitle: (value) => {
        originalDocumentTitle = value;
    },
    getOriginalDocumentTitle: () => {
        return originalDocumentTitle;
    },
    setCurrentBlob: (el) => {
        currentBlob = el;
    },
    getCurrentBlob: () => {
        return currentBlob;
    },
    /** Contains only the doughs that are in scope of prev / next navigation. */
    setOpenDoughs: (doughs) => {
        // always sort the open doughs in prev next
        function compare(a, b) {
            if (a.number < b.number) {
                return -1;
            }
            if (a.number > b.number) {
                return 1;
            }
            return 0;
        }
        var sortedDoughs = Array.from(doughs);
        sortedDoughs.sort(compare);
        openDoughs = Array.from(sortedDoughs);
    },
    /** Contains only the doughs that are in scope of prev / next navigation. */
    getOpenDoughs: () => {
        return openDoughs;
    },
    /** Contains only the doughs that are visible in the fridge */
    setVisibleContinentDoughs: (continentSlug, doughs) => {
        visibleContinentDoughs[continentSlug] = Array.from(doughs);
    },
    /** Contains only the doughs that are visible in the fridge */
    getVisibleContinentDoughs: (continentSlug) => {
        return visibleContinentDoughs[continentSlug];
    },
    /** Contains only the doughs that are visible in the fridge, for ALL continents */
    getAllVisibleContinentDoughs: () => {
        return visibleContinentDoughs;
    },
    /** Contains ALL doughs for a certain continent. Visible & loaded or not. */
    setContinentDoughs: (continentSlug, doughs) => {
        continentDoughs[continentSlug] = doughs;
    },
    /** Contains ALL doughs for a certain continent. Visible & loaded or not. */
    getContinentDoughs: (continentSlug) => {
        return continentDoughs[continentSlug];
    },
    setCamera: (value) => {
        camera = value;
    },
    getCamera: () => {
        return camera;
    },
    setDolly: (value) => {
        dolly = value;
    },
    getDolly: () => {
        return dolly;
    },
    setController1: (value) => {
        controller1 = value;
    },
    getController1: () => {
        return controller1;
    },
    setController2: (value) => {
        controller2 = value;
    },
    getController2: () => {
        return controller2;
    },
    setControllerModel1: (value) => {
        controllerModel1 = value;
    },
    getControllerModel1: () => {
        return controllerModel1;
    },
    setControllerModel2: (value) => {
        controllerModel2 = value;
    },
    getControllerModel2: () => {
        return controllerModel2;
    },
    setVrActive: (value) => {
        vrActive = value;
    },
    getVrActive: () => {
        return vrActive;
    },
    setScene: (value) => {
        scene = value;
    },
    getScene: () => {
        return scene;
    },
    setHtmlScreen: (value) => {
        htmlScreen = value;
    },
    getHtmlScreen: () => {
        return htmlScreen;
    },
    setRenderer: (value) => {
        renderer = value;
    },
    getRenderer: () => {
        return renderer;
    },
    setAllDoughs: (value) => {
        allDoughs = value;
    },
    getAllDoughs: () => {
        return allDoughs;
    },
    setAllArtifacts: (value) => {
        allArtifacts = value;
    },
    getAllArtifacts: () => {
        return allArtifacts;
    },
};

Object.freeze(state);

window.state = state;

export { state };
