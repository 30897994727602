import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { HDRCubeTextureLoader } from "three/examples/jsm/loaders/HDRCubeTextureLoader.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import * as THREE from "three";
import { PreloadingManager } from "./preloadingManager";

var textureLoader = new THREE.TextureLoader(PreloadingManager.getManager());
var objLoader = new OBJLoader(PreloadingManager.getManager());
var rgbeLoader = new RGBELoader(PreloadingManager.getManager());
var gltfLoader = new GLTFLoader(PreloadingManager.getManager());
var cubeTextureLoader = new HDRCubeTextureLoader(PreloadingManager.getManager()).setDataType(
    THREE.UnsignedByteType
);

function composeAssetUrl(uri) {
    if (uri.indexOf("http") === 0) {
        // is an S3 URL
        return uri;
    } else {
        var url = window.staticPathPrefix + uri;
        return url;
    }
}

async function loadFile(uri) {
    return new Promise((resolve, reject) => {
        new THREE.FileLoader(PreloadingManager.getManager())
            .setMimeType("application/octet-stream")
            .setResponseType("arraybuffer")
            .load(
                uri,
                function (tmp) {
                    resolve(tmp);
                },
                undefined, // progress
                function (error) {
                    reject(error);
                }
            );
    });
}

async function loadGLTF(uri) {
    var url = composeAssetUrl(uri);
    return new Promise((resolve, reject) => {
        gltfLoader.load(
            url,
            function (gltf) {
                resolve(gltf);
            },
            undefined, // progress
            function (error) {
                reject(error);
            }
        );
    });
}

async function loadTexture(uri) {
    var url = composeAssetUrl(uri);
    return new Promise((resolve, reject) => {
        textureLoader.load(
            url,
            (texture) => {
                resolve(texture);
            },
            undefined, // not supported
            function (error) {
                reject(error);
            }
        );
    });
}

async function loadOBJ(uri) {
    var url = composeAssetUrl(uri);
    return new Promise((resolve, reject) => {
        objLoader.load(
            url,
            (texture) => {
                resolve(texture);
            },
            undefined, // progress
            function (error) {
                reject(error);
            }
        );
    });
}

async function loadRGBE(uri) {
    var url = composeAssetUrl(uri);
    return new Promise((resolve, reject) => {
        rgbeLoader.load(
            url,
            (texture) => {
                resolve(texture);
            },
            undefined, // progress
            function (error) {
                reject(error);
            }
        );
    });
}

async function loadHDRCubeTexture(renderer, hdrUris, flipY = null) {
    var urls = hdrUris.map((x) => composeAssetUrl(x));
    return new Promise((resolve, reject) => {
        cubeTextureLoader.load(
            urls,
            function (hdrCubeMap) {
                hdrCubeMap.flipY = flipY;
                var hdrCubeRenderTarget = pmremGenerator.fromCubemap(hdrCubeMap);

                hdrCubeMap.magFilter = THREE.LinearFilter;
                hdrCubeMap.needsUpdate = true;

                var renderTarget = hdrCubeRenderTarget;
                //cubeMap = hdrCubeMap;

                var newEnvMap = renderTarget ? renderTarget.texture : null;

                pmremGenerator.dispose();
                resolve(newEnvMap);
            },
            function () {}, // progress
            function (error) {
                reject(error);
            }
        );

        var pmremGenerator = new THREE.PMREMGenerator(renderer);
        pmremGenerator.compileCubemapShader();
    });
}

export { loadGLTF, loadTexture, loadOBJ, loadHDRCubeTexture, loadRGBE, loadFile, composeAssetUrl };
