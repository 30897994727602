export default function () {
    var videoLinks = Array.from(document.querySelectorAll(".video-controls .controls__list .controls__link"));

    videoLinks.forEach(function (el, idx) {
        let vid = el.querySelector(".video video");

        el.addEventListener("mouseover", function () {
            if (vid.paused) {
                var playPromise = vid.play();
                if (playPromise !== undefined) {
                    console.log("playing");
                }
            }
        });
        el.addEventListener("mouseleave", function () {
            vid.pause();
        });
    });

}
