var initialized = false;
function dropUpClickOutsideHandler(evt) {
    let targetElement = evt.target;
    do {
        if (targetElement.classList && targetElement.classList.contains("button-dropup")) {
            // This is a click inside. Do nothing, just return.
            return;
        }
        targetElement = targetElement.parentNode;
    } while (targetElement);

    // This is a click outside.
    if (evt.currentTarget.currentDropup) {
        evt.currentTarget.currentDropup.classList.remove("button-dropup--open");
    }
    document.removeEventListener("click", dropUpClickOutsideHandler, true);
}

function initializeDropups() {
    if (initialized) return;

    initialized = true;

    // Button nav toggler (mobile)
    var buttonNavGroup = document.querySelector(".button-nav-group");
    var buttonNavToggle = document.querySelector(".button-nav-toggle");

    if (buttonNavGroup && buttonNavToggle) {
        buttonNavToggle.addEventListener("click", function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            buttonNavToggle.classList.toggle("button-nav-toggle--open");
            buttonNavGroup.classList.toggle("button-nav-group--open");
        });
    }
    //

    // Dropup component
    var dropUps = Array.from(document.querySelectorAll(".button-dropup"));

    dropUps.forEach(function (el, idx) {
        el.addEventListener("click", function (e) {
            e.preventDefault();
            if (el.classList.contains("button-dropup--open")) {
                e.stopImmediatePropagation();
                el.classList.remove("button-dropup--open");
                el.getElementsByClassName("button-dropup__list-wrapper")[0].classList.remove(
                    "open"
                );
            } else {
                e.stopImmediatePropagation();
                // Close all other dropups
                Array.from(document.querySelectorAll(".button-dropup--open")).forEach(function (
                    dropup,
                    idx
                ) {
                    dropup.classList.remove("button-dropup--open");
                });
                el.classList.add("button-dropup--open");
                document.addEventListener("click", dropUpClickOutsideHandler, true);
                document.currentDropup = el;
                setTimeout(function () {
                    el.getElementsByClassName("button-dropup__list-wrapper")[0].classList.add(
                        "open"
                    );
                }, 250);
            }
        });

        //var dropUpItems = Array.from(el.querySelectorAll(".button-dropup__list > li > a"));
        // no need to change the active item every time. Only the dropups when before a fridge change
        // dropUpItems.forEach(function (item, idx) {
        //     item.addEventListener("click", function (e) {
        //         e.preventDefault();
        //         el.querySelector(".button-dropup__placeholder").innerHTML = item.innerHTML;
        //     });
        // });
    });
}

export { initializeDropups };
