import * as THREE from "three";
import { Lensflare, LensflareElement } from "three/examples/jsm/objects/Lensflare.js";
import { state } from "./state";
import { composeAssetUrl } from "./asyncLoaders";

async function initializeLensflare() {
    var scene = state.getScene();
    var point = new THREE.Object3D();
    point.name = "lensflare";

    var textureLoader = new THREE.TextureLoader();

    var textureFlare0 = textureLoader.load(composeAssetUrl("/lens-flare/lensflare0.png"));
    var textureFlare1 = textureLoader.load(composeAssetUrl("/lens-flare/lensflare1.png"));
    var textureFlare2 = textureLoader.load(composeAssetUrl("/lens-flare/lensflare2.png"));

    var lensflare = new Lensflare();

    lensflare.addElement(new LensflareElement(textureFlare0, 512, 0));
    lensflare.addElement(new LensflareElement(textureFlare1, 512, 6));
    lensflare.addElement(new LensflareElement(textureFlare2, 60, 0.6));

    point.add(lensflare);

    point.position.set(0, 30, -200);
    scene.add(point);
}

export { initializeLensflare };
