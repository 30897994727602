import createTextGeometry from "three-bmfont-text";
import karmina from "../fonts/karmina";
import sansapro from "../fonts/sansa-pro";
import sourcesanspro from "../fonts/sourcesanspro";
import sourceserifpro from "../fonts/sourceserifpro";
import { fridgePositions } from "../common/fridge-positions";
import { addFrustumCullingMesh } from "../common/frustum-culling";
import {
    getKarminaMaterial,
    getSansaProMaterial,
    getSourceSansProMaterial,
    getSourceSerifProMaterial,
} from "../common/fonts";
import { LAYER_HIDEVR } from "../common/constants";

var serifMaterial, sansSerifMaterial;
var smokeNodes = [];
var smokeCloud = [];
var numParticles = 20;
//var clock = new THREE.Clock();

function slugify(str) {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

    return str;
}

function addDustToFridge(continent, scene, fridge) {
    THREE.ImageUtils.crossOrigin = "";
    let smokeTexture = new THREE.TextureLoader().load(
        "https://s3.eu-west-3.amazonaws.com/puratos-sourdough-museum/files/smoke/smoke-element.png"
    );

    var bounds = {
        x: 0.35,
        y: 0.35,
        z: 0.35,
    };

    var smokeNode = new THREE.Object3D();
    smokeNode.layers.set(LAYER_HIDEVR);
    smokeNode.position.copy(fridge.position);

    smokeNode.position.y -= 1.95;

    if (fridge.kind === "left") {
        smokeNode.position.x += 0.25;
        //smokeNode.position.z += 0.20;
    }

    if (fridge.kind === "right") {
        smokeNode.position.x -= 0.25;
        //smokeNode.position.z += 0.20;
    }

    for (let p = 0; p < numParticles; p++) {
        const sprite = new THREE.Sprite(
            new THREE.SpriteMaterial({
                map: smokeTexture,
                transparent: true,
                depthTest: true,
                depthWrite: false,
                opacity: 0.085 /*0.101*/,
                blending: THREE.AdditiveBlending,
            })
        );
        sprite.layers.set(LAYER_HIDEVR);

        sprite.position.set(
            Math.random() * bounds.x - bounds.x * 0.5,
            Math.random() * bounds.y - bounds.y * 0.5,
            Math.random() * bounds.z - bounds.z * 0.6
        );

        const velocity = {
            x: (Math.random() - 0.15) * 0.15,
            y: (Math.random() - 0.15) * 0.15,
            z: (Math.random() - 0.15) * 0.15,
        };

        sprite.userData = { diff: Math.random() + 0.2, velocity: velocity, limit: 0.25 };

        smokeNode.add(sprite);
        smokeCloud.push(sprite);
    }

    smokeNodes.push(smokeNode);

    scene.add(smokeNode);
}

function getLimit(particle) {
    if (
        particle.position.x > particle.userData.limit ||
        particle.position.x < -particle.userData.limit
    ) {
        particle.userData.velocity.x = -particle.userData.velocity.x;
    }

    if (
        particle.position.y > particle.userData.limit ||
        particle.position.y < -particle.userData.limit
    ) {
        particle.userData.velocity.y = -particle.userData.velocity.y;
    }

    if (
        particle.position.z > particle.userData.limit ||
        particle.position.z < -particle.userData.limit
    ) {
        particle.userData.velocity.z = -particle.userData.velocity.z;
    }
}

function rotateSmoke() {
    //var delta = clock.getDelta();

    //smokeNode.rotation.y += 0.0001;

    for (let i = 0; i < smokeCloud.length; i++) {
        if (smokeNodes[i]) smokeNodes[i].rotation.y += 0.0001;

        var particle = smokeCloud[i];
        //var move = ( Math.sin(clock.getElapsedTime() * (1 * smokeCloud[i].userData.diff)) / 4 ) * 0.007;

        //particle.position.x += move;
        //particle.position.y += move;
        //particle.position.z += move;

        particle.position.x += smokeCloud[i].userData.velocity.x * 0.01;
        particle.position.y += smokeCloud[i].userData.velocity.y * 0.01;
        particle.position.z += smokeCloud[i].userData.velocity.z * 0.01;

        getLimit(particle);
    }
}

async function createFridgeTitle(text) {
    // Errors in console need a fix upstream: https://github.com/Jam3/three-buffer-vertex-data/issues/3
    // create a geometry of packed bitmap glyphs,
    var geometry = createTextGeometry({
        text: text,
        font: window.fallbackFont ? sourceserifpro : karmina,
        align: "left",
        flipY: true,
    });

    // the resulting layout has metrics and bounds
    // console.log(geometry.layout.height);
    // console.log(geometry.layout.descender);

    serifMaterial = window.fallbackFont
        ? await getSourceSerifProMaterial(0xffffff)
        : await getKarminaMaterial(0xffffff);
    var layout = geometry.layout;
    var mesh = new THREE.Mesh(geometry, serifMaterial);
    mesh.frustumCulled = false;
    addFrustumCullingMesh(mesh);

    mesh.position.y = 0;
    mesh.position.z = 0;
    mesh.position.x = (-1 * layout.width) / 2;

    var group = new THREE.Group();
    group.add(mesh);
    group.scale.set(0.004, -0.004, 0.004);

    group.name = "fridge_title_" + slugify(text);

    return group;
}

async function createFridgeSubtitle(text) {
    // Errors in console need a fix upstream: https://github.com/Jam3/three-buffer-vertex-data/issues/3
    // create a geometry of packed bitmap glyphs,
    var geometry = createTextGeometry({
        text: text,
        font: window.fallbackFont ? sourcesanspro : sansapro,
        align: "left",
        flipY: true,
    });

    // the resulting layout has metrics and bounds
    // console.log(geometry.layout.height);
    // console.log(geometry.layout.descender);

    sansSerifMaterial = window.fallbackFont
        ? await getSourceSansProMaterial(0xffffff)
        : await getSansaProMaterial(0xffffff);
    var layout = geometry.layout;
    var mesh = new THREE.Mesh(geometry, sansSerifMaterial);
    mesh.frustumCulled = false;
    addFrustumCullingMesh(mesh);

    mesh.position.y = 0;
    mesh.position.z = 0;
    mesh.position.x = (-1 * layout.width) / 2;

    var group = new THREE.Group();
    group.add(mesh);
    group.scale.set(0.0011, -0.0011, 0.0011);

    group.name = "fridge_subtitle_" + slugify(text);

    return group;
}

async function addFridgeText(continentSlug, scene) {
    const continent = window.continents.find((x) => x.slug === continentSlug);
    const fridge = fridgePositions[continentSlug];

    var title = await createFridgeTitle(continent.title);
    var subtitle = await createFridgeSubtitle(continent.subtitle);

    title.position.copy(fridge.position);
    title.rotation.y = THREE.MathUtils.degToRad(fridge.r_y);
    scene.add(title);

    subtitle.position.copy(fridge.position);
    subtitle.position.y -= 0.085;
    subtitle.rotation.y = THREE.MathUtils.degToRad(fridge.r_y);
    scene.add(subtitle);

    addDustToFridge(continentSlug, scene, fridge);
}

async function initializeFridgeText(scene) {
    await Promise.all([
        addFridgeText("north-america", scene),
        addFridgeText("south-america", scene),
        addFridgeText("australia", scene),
        addFridgeText("europe", scene),
        addFridgeText("africa", scene),
        addFridgeText("asia", scene),
    ]);
}

export { initializeFridgeText, rotateSmoke };
