import { fridgePositions } from "../common/fridge-positions";
import { openBlobAndShowUI, openCountryAndShowUI } from "../ui/ui-nonvr";
import { setSelectedValueCountry } from "./blob-nav";
import { moveAndRotateCamera } from "./animations";
import { state } from "./state";
import { setBlobToRotate } from "../model/blobs";
import { fetchDoughAndShow } from "../ui/ui-nonvr";
import tippy from "tippy.js";

var wrapper,
    initialized = false;
var errorTip;

function initializeRoomNav() {
    if (initialized) return;

    initialized = true;

    initializeContinentNav();
    initializeCountryNav();
}

function hideRoomNav() {
    wrapper = document.getElementById("button-nav");
    wrapper.classList.remove("button-nav--open");
}

function showRoomNav() {
    wrapper = document.getElementById("button-nav");
    wrapper.classList.add("button-nav--open");
}

function initializeContinentNav() {
    Array.from(document.querySelectorAll(".continent-select")).forEach((item) => {
        item.addEventListener("click", (e) => {
            var slug = item.getAttribute("data-continent-slug");
            var doughs = state.getVisibleContinentDoughs(slug);
            if (doughs && doughs.length) {
                var dough = doughs[0];
                var firstBlob = dough.blob;
                state.setOpenDoughs(state.getContinentDoughs(slug));
                openBlobAndShowUI(firstBlob);
                setBlobToRotate(dough.blob);
            } else {
                // no blobs in fridge; Just move to fridge.
                var fridge = fridgePositions[slug];
                moveAndRotateCamera(fridge.marker);
            }
        });
    });
}

function initializeCountryNav() {
    Array.from(document.querySelectorAll(".country-select")).forEach((item) => {
        item.addEventListener("click", (e) => {
            var countryId = item.getAttribute("data-id");
            openCountryAndShowUI(countryId);

            setSelectedValueCountry(item.innerHTML);
        });
    });
}

function initializeRoomNavSearch() {
    var roomSearchForm = document.getElementById("room-nav-search-number-form");
    var roomSearchInput = document.getElementById("room-nav-search-number");
    if (roomSearchInput && roomSearchForm) {
        roomSearchInput.addEventListener("keydown", function () {
            if (errorTip) errorTip.destroy();
            roomSearchInput.classList.remove("has-error");
        });
        roomSearchForm.addEventListener("submit", function (e) {
            e.preventDefault();
            var val = roomSearchInput.value;

            var dough = state.getAllDoughs().find((x) => x.number == val);
            if (!dough) {
                errorTip = tippy(roomSearchInput, {
                    showOnCreate: true,
                    theme: "error",
                    content(ref) {
                        const title = ref.getAttribute("data-error");
                        return title;
                    },
                    placement: "top-start",
                    offset: [0, 10],
                });
                roomSearchInput.classList.add("has-error");
                return;
            }

            if (errorTip) errorTip.destroy();
            roomSearchInput.classList.remove("has-error");
            roomSearchInput.value = "";
            setTimeout(() => {
                fetchDoughAndShow(dough);
            });
        });
    }
}

export { hideRoomNav, showRoomNav, initializeRoomNav, initializeRoomNavSearch };
