import request from "../common/superagent";
import { htmlEncode } from "js-htmlencode";
import tippy from "tippy.js";
import { state } from "../common/state";

function resetUI(obj) {
    obj.querySelector(".property-title").innerHTML = "";
    obj.querySelector(".property-number").innerHTML = "";
    obj.querySelector(".property-number").className = "value property-number";
    obj.querySelector(".property-description").innerHTML = "";
    obj.querySelector(".property-country").innerHTML = "";
    obj.querySelector(".property-owner").innerHTML = "";
    obj.querySelector(".property-flourtype").innerHTML = "";
    obj.querySelector(".property-yeastSpecies").innerHTML = "";
    obj.querySelector(".property-yeastSpecies-panel").classList.add("block-line");
    obj.querySelector(".property-lacticAcidSpecies").innerHTML = "";
    obj.querySelector(".property-bakedgoods").innerHTML = "";
    obj.querySelector(".property-video").setAttribute("href", "#");
    obj.querySelector(".property-video .img").setAttribute("src", "");
    obj.querySelector(".property-image .img").setAttribute("src", "");
}

function composeTagArray(items) {
    var arr = items.map(function (x) {
        var popup = x.popup ? htmlEncode(x.popup) : "";
        return "<span class='tag' data-tippy-content='" + popup + "'>" + x.title + "</span>";
    });
    return arr.join(", ");
}

function changeUIValues(obj, meta) {
    obj.classList.add("panel-nonvr--loading");
    resetUI(obj);

    obj.querySelector(".property-title").innerHTML = meta.title;

    request
        .get(`${window.baseApiUrl}/api/sourdoughs/${meta.id}`)
        .query({ dt: Date.now() })
        .then((result) => {
            var dto = result.body;
            var nr = dto.number || "";
            obj.querySelector(".property-description").innerHTML = dto.description;
            obj.querySelector(".property-country").innerHTML = dto.country;
            obj.querySelector(".property-number").innerHTML = nr;
            if (nr.toString().length == 3)
                obj.querySelector(".property-number").className = "value property-number value--3";
            else if (nr.toString().length > 3)
                obj.querySelector(".property-number").className = "value property-number value--4";
            obj.querySelector(".property-flourtype").innerHTML = dto.flourType.join(", ");
            obj.querySelector(".property-bakedgoods").innerHTML = dto.bakedGoods;

            if (dto.bakedGoods)
                obj.querySelector(".property-bakedgoods-panel").style.display = "flex";
            else obj.querySelector(".property-bakedgoods-panel").style.display = "none";

            if (dto.flourType.length)
                obj.querySelector(".property-flourtype-panel").style.display = "flex";
            else obj.querySelector(".property-flourtype-panel").style.display = "none";

            if (dto.owner) obj.querySelector(".property-owner-panel").style.display = "flex";
            else obj.querySelector(".property-owner-panel").style.display = "none";

            obj.querySelector(".property-owner").innerHTML = dto.owner;

            if (dto.yeastSpecies.length) {
                obj.querySelector(".property-yeastSpecies-panel").style.display = "flex";
            } else {
                obj.querySelector(".property-yeastSpecies-panel").style.display = "none";
                obj.querySelector(".property-yeastSpecies-panel").classList.remove("block-line");
            }

            if (dto.lacticAcidSpecies.length)
                obj.querySelector(".property-lacticAcidSpecies-panel").style.display = "flex";
            else obj.querySelector(".property-lacticAcidSpecies-panel").style.display = "none";

            // hide complete mibrobial panel
            if (!dto.yeastSpecies.length && !dto.lacticAcidSpecies.length)
                obj.querySelector(".property-microbial-panel").style.display = "none";
            else obj.querySelector(".property-microbial-panel").style.display = "block";

            obj.querySelector(".property-yeastSpecies").innerHTML = composeTagArray(
                dto.yeastSpecies
            );
            obj.querySelector(".property-lacticAcidSpecies").innerHTML = composeTagArray(
                dto.lacticAcidSpecies
            );

            if (dto.image) {
                obj.querySelector(".property-image").style.display = "block";
                obj.querySelector(".property-image .img").setAttribute("src", dto.image);
            } else {
                obj.querySelector(".property-image").style.display = "block";
                obj.querySelector(".property-image .img").setAttribute("src", "");
            }
            if (dto.videoLink) {
                obj.querySelector(".property-video-panel").style.display = "block";
                obj.querySelector(".property-video").setAttribute("href", dto.videoLink);
                obj.querySelector(".property-video .img").setAttribute(
                    "src",
                    dto.videoPreviewImage
                );
            } else {
                obj.querySelector(".property-video-panel").style.display = "none";
                obj.querySelector(".property-video").setAttribute("href", "");
                obj.querySelector(".property-video .img").setAttribute("src", "");
            }

            obj.classList.remove("panel-nonvr--loading");

            if (!state.getVrActive()) {
                setTimeout(() => {
                    // tooltips
                    tippy(".tag[data-tippy-content]:not([data-tippy-content=''])", {
                        //hideOnClick: false,
                        // trigger: "click",
                        placement: "top-end",
                        offset: [-15, 22],
                    });
                });
            }
        });
}

export { changeUIValues, composeTagArray };
