import { state } from "./common/state";
import { loadRGBE } from "./common/asyncLoaders";
import { LAYER_HIDEVR } from "./common/constants";

var currentSession;

function endVrSession() {
    if (currentSession) currentSession.end();
}

var VRButton = {
    createButton: function (renderer, options, onSessionStartedCallback, onSessionEndedCallback) {
        if (options && options.referenceSpaceType) {
            renderer.xr.setReferenceSpaceType(options.referenceSpaceType);
        }

        function showEnterVR(/*device*/) {
            currentSession = null;

            function onSessionStarted(session) {
                session.addEventListener("end", onSessionEnded);

                var renderer = state.getRenderer();
                renderer.xr.setSession(session);
                //button.textContent = "EXIT VR";

                currentSession = session;
            }

            function onSessionEnded(/*event*/) {
                currentSession.removeEventListener("end", onSessionEnded);

                //button.textContent = "ENTER VR";
                currentSession = null;

                if (onSessionEndedCallback) {
                    onSessionEndedCallback();
                }

                state.setVrActive(false);
            }

            //

            button.style.display = "";

            button.onmouseenter = function () {
                button.style.opacity = "1.0";
            };

            button.onmouseleave = function () {
                button.style.opacity = "0.5";
            };

            button.onclick = function () {
                if (currentSession === null) {
                    if (onSessionStartedCallback) {
                        onSessionStartedCallback();
                    }

                    state.setVrActive(true);

                    // WebXR's requestReferenceSpace only works if the corresponding feature
                    // was requested at session creation time. For simplicity, just ask for
                    // the interesting ones as optional features, but be aware that the
                    // requestReferenceSpace call will fail if it turns out to be unavailable.
                    // ('local' is always available for immersive sessions and doesn't need to
                    // be requested separately.)

                    var sessionInit = { optionalFeatures: ["local-floor", "bounded-floor"] };
                    navigator.xr.requestSession("immersive-vr", sessionInit).then(onSessionStarted);
                } else {
                    currentSession.end();
                }
            };
        }

        function disableButton() {
            button.style.display = "";

            button.style.cursor = "auto";

            button.onmouseenter = null;
            button.onmouseleave = null;

            button.onclick = null;
        }

        function showWebXRNotFound() {
            disableButton();

            //button.textContent = "VR NOT SUPPORTED";
        }

        function stylizeElement(element) {
            element.className = "vr-button";
            element.innerHTML =
                '<svg height="20" viewBox="0 0 32 20" width="32" xmlns="http://www.w3.org/2000/svg"><path d="m9.61098633 19.2000358c1.25952147 0 2.40151367-.7384766 2.91801757-1.8870118l1.3870117-3.0819824c.3944824-.8770019 1.2014649-1.4310058 2.0839844-1.4310058s1.689502.5540039 2.0839844 1.4310058l1.3870117 3.0819824c.5165039 1.1485352 1.6589844 1.8870118 2.9180176 1.8870118h8.0109863c.8834961 0 1.6-.7165039 1.6-1.6v-16.00000004c0-.88349609-.7165039-1.6-1.6-1.6h-28.8c-.88349609 0-1.6.71650391-1.6 1.6v16.00000004c0 .8834961.71650391 1.6 1.6 1.6zm-1.61098633-7.2c-1.76748047 0-3.2-1.4325196-3.2-3.20000004 0-1.76748047 1.43251953-3.2 3.2-3.2s3.2 1.43251953 3.2 3.2c0 1.76748044-1.43251953 3.20000004-3.2 3.20000004zm16 0c-1.7674805 0-3.2-1.4325196-3.2-3.20000004 0-1.76748047 1.4325195-3.2 3.2-3.2s3.2 1.43251953 3.2 3.2c0 1.76748044-1.4325195 3.20000004-3.2 3.20000004z" fill="#fff"/></svg>';
        }

        if ("xr" in navigator) {
            var button = document.createElement("button");
            button.style.display = "none";

            stylizeElement(button);

            navigator.xr.isSessionSupported("immersive-vr").then(function (supported) {
                supported ? showEnterVR() : showWebXRNotFound();
            });

            return button;
        } else {
            var message = document.createElement("a");

            if (window.isSecureContext === false) {
                message.href = document.location.href.replace(/^http:/, "https:");
                message.innerHTML = "WEBXR NEEDS HTTPS"; 
            } else {
                message.href = "https://immersiveweb.dev/";
                message.innerHTML = "WEBXR NOT AVAILABLE";
            }

            // message.style.width = "180px";
            // message.style.textDecoration = "none";

            stylizeElement(message);

            return message;
        }
    },
};

export { VRButton, endVrSession };
