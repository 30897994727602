import html2canvas from "html2canvas";
import { state } from "../common/state";
import { controller1, controller2 } from "../vr-controller";
import { hasClickedElement, convertLocalCoordsToPixels } from "./ui-vr-common";
import { hideVrBlobUiIfOpen } from "../model/blobs";
import { endVrSession } from "../vr-button";
import TWEEN from "@tweenjs/tween.js";

const screenWidth = 800; // pixels, should be the same as CSS size of the HTML element

var texture,
    screen,
    artifactScreenArray,
    raycaster,
    material,
    mouse,
    renderer,
    camera,
    screenHeight;
var tempMatrix = new THREE.Matrix4();

async function createTexture() {
    var elmnt = document.getElementById("panel-vr-ui-artifacts");
    elmnt.style.display = "block";

    var canvas = await html2canvas(elmnt, {
        useCORS: true,
        backgroundColor: null,
        logging: true,
        scale: 2, // increase PPI
        width: screenWidth,
    });
    // derive height from the calculated canvas height
    screenHeight = parseInt(canvas.style.height.substr(0, canvas.style.height.indexOf("px")));
    // debugging the canvas
    // canvas.style.left = "0px";
    // canvas.style.top = "0px";
    // canvas.style.position = "absolute";
    // document.body.appendChild(canvas);

    texture = new THREE.CanvasTexture(canvas);
    texture.minFilter = THREE.LinearFilter;
    texture.wrapS = THREE.ClampToEdgeWrapping;
    texture.wrapT = THREE.ClampToEdgeWrapping;

    elmnt.style.display = "none";

    return texture;
}

async function embedArtifactHtml() {
    var scene = state.getScene();

    var texture = await createTexture();
    material = new THREE.MeshBasicMaterial({
        map: texture,
        flatShading: true,
        transparent: true,
        toneMapped: false,
    });

    var group = new THREE.Group();
    group.name = "screen-artifact-group";
    var geometry = new THREE.PlaneGeometry(1, 1);
    screen = new THREE.Mesh(geometry, material);
    artifactScreenArray = [screen];
    console.log("SCREENARRAY", artifactScreenArray);
    //screen.scale.set(width, height, 1);
    screen.name = "screen-artifact";

    screen.visible = false;
    scene.add(screen);

    return group;
}

function setUI(meta) {
    const panel = document.getElementById("panel-vr-ui-artifacts");
    panel.querySelector(".title").innerHTML = meta.title;
    panel.querySelector(".description").innerHTML = meta.description;
    panel.querySelector(".img").src = meta.imageVr;
}

function hideVrArtifactUiIfOpen(immediate) {
    if (immediate) {
        screen.visible = false;
        return;
    }
    // hide VR artifact screen if open
    if (screen.visible) {
        new TWEEN.Tween(screen.position)
            .to({ y: 1.2 })
            .easing(TWEEN.Easing.Quadratic.Out)
            .duration(800)
            .start();
        new TWEEN.Tween(screen.material)
            .to({ opacity: 0 })
            .easing(TWEEN.Easing.Quadratic.Out)
            .duration(800)
            .onComplete(() => {
                screen.visible = false;
            })
            .start();
    }
}

function showArtifactVrUi(meta, artifactMesh, artifactPosition) {
    hideVrBlobUiIfOpen(true);
    hideVrArtifactUiIfOpen(true);

    setUI(meta);

    var pos = new THREE.Vector3(artifactPosition.position.x, 1.2, artifactPosition.position.z);

    var sideDistance = 0.8;
    var wallDistance = 0.3;
    if (meta.number === 4 || meta.number === 5) {
        // right
        pos.z += sideDistance;
        pos.x -= wallDistance;
    } else if (meta.number === 2 || meta.number === 3) {
        // left
        pos.z -= sideDistance;
        pos.x += wallDistance;
    } else if (meta.number === 1 || meta.number === 6) {
        // back
        pos.x -= sideDistance;
        pos.z -= wallDistance;
    }

    screen.position.copy(pos);

    var rotationDistance = 20;
    var rot = 0;
    if (meta.number === 4 || meta.number === 5) {
        rot = -90 - rotationDistance;
    } else if (meta.number === 2 || meta.number === 3) {
        rot = 90 - rotationDistance;
    } else if (meta.number === 1 || meta.number === 6) {
        rot = -180 - rotationDistance;
    }

    screen.rotation.set(0, THREE.MathUtils.degToRad(rot), 0);

    createTexture().then((texture) => {
        material.map = texture;
        material.needsUpdate = true;

        // debugger;
        var heightScale = screenHeight / screenWidth;
        screen.scale.set(1.25, heightScale * 1.25, 1.25);
        screen.material.opacity = 0;

        screen.visible = true;

        setTimeout(() => {
            new TWEEN.Tween(screen.position)
                .to({ y: 1.6 })
                .easing(TWEEN.Easing.Quadratic.Out)
                .duration(800)
                .start();
            new TWEEN.Tween(screen.material)
                .to({ opacity: 1 })
                .easing(TWEEN.Easing.Quadratic.Out)
                .duration(800)
                .start();
        });
    });
}

function onMouseClick() {
    // normalized mouse coords
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    raycaster.setFromCamera(mouse, camera);

    // calculate objects intersecting the picking ray
    var intersects = raycaster.intersectObjects(artifactScreenArray);

    var intersection = null;
    if (intersects.length) {
        intersection = intersects[0];
    }

    testIntersection(intersection);
}

function testControllerRayCast(controller) {
    tempMatrix.identity().extractRotation(controller.matrixWorld);

    raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
    raycaster.ray.direction.set(0, 0, -1).applyMatrix4(tempMatrix);

    // calculate objects intersecting the picking ray
    var intersects = raycaster.intersectObjects(artifactScreenArray);

    var intersection = null;
    if (intersects.length) {
        intersection = intersects[0];
    }

    testIntersection(intersection);
}

function onControllerClick(event) {
    if (!state.getVrActive()) return;

    testControllerRayCast(controller1);
    testControllerRayCast(controller2);
}

function testIntersection(intersection) {
    if (!intersection) return;

    // calculate coords of the point clicked in HTML/CSS space
    var { x, y } = {
        ...convertLocalCoordsToPixels(screen, screenWidth, screenHeight, intersection),
    };

    // check if we have clicked something
    var panel = document.getElementById("panel-vr-ui-artifacts");
    var closeEl = document.getElementById("vr-close-artifact-button");
    if (hasClickedElement(panel, closeEl, x, y)) {
        hideVrArtifactUiIfOpen();
    } else {
        var elements = Array.from(panel.querySelectorAll(".description a"));
        elements.forEach((elmnt) => {
            if (hasClickedElement(panel, elmnt, x, y)) {
                window.open(elmnt.getAttribute("href"), "_blank");
                endVrSession();
            }
        });
    }
}

function initializeArtifactUiVr() {
    console.log("Initlialize VR UI Artifacts");

    var res = embedArtifactHtml();

    raycaster = new THREE.Raycaster();
    mouse = new THREE.Vector2();

    // bind events

    camera = state.getCamera();
    renderer = state.getRenderer();
    var domEl = renderer.domElement;
    domEl.addEventListener("click", onMouseClick);

    controller1.addEventListener("selectend", onControllerClick);
    controller2.addEventListener("selectend", onControllerClick);

    return res;
}

export { initializeArtifactUiVr, showArtifactVrUi, hideVrArtifactUiIfOpen };
