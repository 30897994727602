import * as THREE from "three";
import { isMobile } from "./common/device";
import { VRButton } from "./vr-button";
import { isWebXrEnabled } from "./web-xr";
import { state } from "./common/state";

var camera, scene, renderer, material, geometry, mesh, video, audioButton;
var pitchObject, yawObject, previousMouseEvent, magicWindowDeltaEuler;
var touchStart, touchStarted, videoControls, range, wasPaused;

//var euler = new THREE.Euler(0, 0, 0, "YXZ");
var PI_2 = Math.PI / 2;

var isUserInteracting = false;

function adjustVolumeButton() {
    if (!audioButton) return;
    if (video.muted == true || video.paused) {
        audioButton.classList.remove("playing");
    } else {
        audioButton.classList.add("playing");
    }
}

function initializeScene() {
    pitchObject = new THREE.Object3D();
    yawObject = new THREE.Object3D();
    magicWindowDeltaEuler = new THREE.Euler();
    yawObject.position.y = 10;
    yawObject.add(pitchObject);

    // delete intro stuff if present
    var intro = document.getElementById("intro");
    if (intro) intro.parentNode.removeChild(intro);

    document.body.classList.add("body-overflow");

    // delete navigation button if present (it is if coming from the museum)
    var navButton = document.querySelector(".mobile-nav__toggler");
    if (navButton) navButton.parentNode.removeChild(navButton);

    // delete museum scene if present
    var deleteContainer = document.getElementById("scene-container");
    if (deleteContainer) deleteContainer.parentNode.removeChild(deleteContainer);

    // create container
    var container = document.createElement("div");
    container.setAttribute("class", "scene-container");
    container.setAttribute("id", "scene-container");
    document.getElementById("content").appendChild(container);

    camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 1100);
    camera.rotation.order = "YXZ"; // https://discourse.threejs.org/t/camera-rotation-for-first-person-controls-not-correctly/5476/4
    camera.target = new THREE.Vector3(0, 0, 0);

    scene = new THREE.Scene();

    geometry = new THREE.SphereBufferGeometry(500, 60, 40);
    // invert the geometry on the x-axis so that all of the faces point inward
    geometry.scale(-1, 1, 1);

    var texture = new THREE.VideoTexture(video);
    material = new THREE.MeshBasicMaterial({ map: texture });

    mesh = new THREE.Mesh(geometry, material);

    scene.add(mesh);

    renderer = new THREE.WebGLRenderer();
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.xr.enabled = true;
    state.setRenderer(renderer);

    container.appendChild(renderer.domElement);

    document.addEventListener("mousedown", onMouseDown, false);
    document.addEventListener("mousemove", onMouseMove, false);
    document.addEventListener("mouseup", onMouseUp, false);
    document.addEventListener("touchstart", onTouchStart);
    document.addEventListener("touchend", onTouchEnd);
    document.addEventListener("touchmove", onTouchMove);

    window.addEventListener("resize", onWindowResize, false);
}

function onWindowResize() {
    if (!camera) return;
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);
}

function onTouchStart(evt) {
    //if (evt.touches.length !== 1 || !this.data.touchEnabled || this.el.sceneEl.is("vr-mode")) {
    if (evt.touches.length !== 1) {
        return;
    }
    touchStart = {
        x: evt.touches[0].pageX,
        y: evt.touches[0].pageY,
    };
    touchStarted = true;
}

function onTouchMove(evt) {
    var direction;
    var deltaY, deltaX;

    if (!touchStarted) {
        return;
    }

    deltaY = (2 * Math.PI * (evt.touches[0].pageX - touchStart.x)) / window.innerWidth;
    deltaX = (2 * Math.PI * (evt.touches[0].pageY - touchStart.y)) / window.innerHeight;

    direction = -1;
    // Limit touch orientaion to to yaw (y axis).
    yawObject.rotation.y -= deltaY * 0.4 * direction;
    pitchObject.rotation.x += deltaX * 0.4 * -direction;
    pitchObject.rotation.x = Math.max(-PI_2, Math.min(PI_2, pitchObject.rotation.x));
    touchStart = {
        x: evt.touches[0].pageX,
        y: evt.touches[0].pageY,
    };
}

/**
 * Register touch end to detect release of touch drag.
 */
function onTouchEnd() {
    touchStarted = false;
}

function setGrabbingCursor(active) {
    if (active) renderer.domElement.style.cursor = "grabbing";
    else renderer.domElement.style.cursor = "grab";
}

function onMouseDown(event) {
    if (event.target !== renderer.domElement) return;

    event.preventDefault();

    isUserInteracting = true;
    setGrabbingCursor(true);
    previousMouseEvent = event;
}

function onMouseMove(event) {
    if (isUserInteracting === true) {
        var direction;
        var movementX;
        var movementY;

        movementX = event.screenX - previousMouseEvent.screenX;
        movementY = event.screenY - previousMouseEvent.screenY;

        previousMouseEvent = event;

        // Calculate rotation.
        direction = 1; //this.data.reverseMouseDrag ? 1 : -1;
        yawObject.rotation.y += movementX * 0.002 * direction;
        pitchObject.rotation.x += movementY * 0.002 * direction;
        pitchObject.rotation.x = Math.max(-PI_2, Math.min(PI_2, pitchObject.rotation.x));
    }
}

function onMouseUp() {
    setGrabbingCursor(false);
    isUserInteracting = false;
}

function animate() {
    if (pitchObject && yawObject) {
        camera.rotation.x = pitchObject.rotation.x;
        camera.rotation.y = yawObject.rotation.y;
        camera.rotation.z = magicWindowDeltaEuler.z;

        range.value = Math.round(video.currentTime, 2);
        var pct = Math.round((video.currentTime / video.duration) * 100, 2);
        range.style.backgroundImage =
            "linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,1) " +
            pct +
            "%, rgba(255,255,255,0.5) " +
            pct +
            "%, rgba(255,255,255,0.5) 100%)";
    }
    renderer.render(scene, camera);
}

function showEndScreen() {
    document.getElementById("panel-video-endscreen").classList.add("fadein");
    videoControls.classList.remove("active");
    document.getElementById("endscreen-view-again").addEventListener("click", function (e) {
        e.preventDefault();
        document.getElementById("panel-video-endscreen").classList.remove("fadein");
        document.getElementById("panel-video-endscreen").classList.add("fadeout");
        setTimeout(() => {
            video.currentTime = 0;
            video.play();
            videoControls.classList.add("active");
        }, 500);
    });
}

function tryAddWebXrButton() {
    function create() {
        var vrButton = VRButton.createButton(renderer);
        vrButton.classList.add("vr-button");
        document
            .getElementById("video-controls")
            .insertBefore(vrButton, document.getElementById("audio-toggle"));
    }

    if (!isMobile()) {
        isWebXrEnabled().then((enabled) => {
            console.log("Webvr enabled", enabled);

            if (enabled) {
                create();
            }
        });
    }
}

function initializeVideo() {
    if (window.pageType != "video") return;

    video = document.createElement("video");
    range = document.getElementById("range");
    var src;
    if (isMobile()) {
        src =
            "https://s3.eu-west-3.amazonaws.com/puratos-sourdough-museum/files/video/history-mobile.mp4";
        if (window.videoUrl360Mobile) src = window.videoUrl360Mobile;
    } else {
        src = "https://s3.eu-west-3.amazonaws.com/puratos-sourdough-museum/files/video/history.mp4";
        if (window.videoUrl360) src = window.videoUrl360;
    }
    console.log("Loading video", src);
    video.setAttribute("src", src);
    video.setAttribute("playsinline", "playsinline");
    video.setAttribute("webkit-playsinline", "webkit-playsinline");
    video.setAttribute("style", "display: none");
    video.setAttribute("crossorigin", "anonymous");
    //video.addEventListener("ended", showEndScreen);
    video.addEventListener("ended", () => {
        // redirect to museum
        window.location.href = window.museumUrl;
    });
    video.addEventListener("durationchange", () => {
        range.max = Math.round(video.duration);
    });
    range.addEventListener("input", () => {
        wasPaused = video.paused;
        video.currentTime = range.value;
    });
    range.addEventListener("mousedown", () => {
        video.pause();
    });
    range.addEventListener("mouseup", () => {
        if (!wasPaused) video.play();
    });

    document.body.appendChild(video);

    videoControls = document.getElementById("video-controls");

    var closeButton = document.getElementById("button-close-video-tutorial");
    closeButton.addEventListener("click", function () {
        var playPromise = video.play();
        if (playPromise !== undefined) {
            playPromise.then(function () {
                //  playback started!
                document
                    .getElementById("panel-video-tutorial")
                    .classList.add("video-tutorial--hidden");
                initializeVideoNow();
                videoControls.classList.add("active");
            });
        } else {
            document.getElementById("panel-video-tutorial").classList.add("video-tutorial--hidden");
            videoControls.classList.add("active");
            initializeVideoNow();
        }
    });

    // endscreen videos
    // var videoLinks = Array.from(document.querySelectorAll(".video-endscreen .controls__link"));

    // videoLinks.forEach(function (el, idx) {
    //     let vid = el.querySelector(".video video");

    //     el.addEventListener("mouseover", function () {
    //         if (vid.paused) {
    //             var playPromise = vid.play();
    //             if (playPromise !== undefined) {
    //                 console.log("playing");
    //             }
    //         }
    //     });
    //     el.addEventListener("mouseleave", function () {
    //         if (!vid.paused) vid.pause();
    //     });
    // });

    video.addEventListener("play", function () {
        pauseButton.classList.add("playing");
        adjustVolumeButton();
    });
    video.addEventListener("pause", function () {
        pauseButton.classList.remove("playing");
        adjustVolumeButton();
    });
    video.addEventListener("volumechange", function () {
        adjustVolumeButton();
    });

    audioButton = document.getElementById("audio-toggle");
    audioButton.addEventListener("click", function () {
        if (video.muted) {
            video.muted = false;
        } else {
            video.muted = true;
        }
    });

    var pauseButton = document.getElementById("playpause");
    pauseButton.addEventListener("click", function () {
        if (video.paused) {
            video.play();
        } else {
            video.pause();
        }
    });

    tryAddWebXrButton();
}

function initializeVideoNow() {
    initializeScene();
    renderer.setAnimationLoop(animate);
}

export { initializeVideoNow, initializeVideo };
