import * as THREE from "three";
import { loadOBJ } from "./common/asyncLoaders";
import anime from "animejs/lib/anime.es.js";
import { getCurrentCameraRotation } from "./lookControls";
import { state } from "./common/state";
import {
    showGrabCursor,
    showPointerCursor,
    showPointerCursorDefined,
    isGrabbing,
    isOn,
} from "./common/cursors";
import { isMobile } from "./common/device";
import { checkArtifacts } from "./model/artifacts";
import { checkBlobs } from "./model/blobs";
import { controller1, controller2 } from "./vr-controller";

var cam, raycaster, mouse, marker, navMesh, navMeshArray, intersection, beginLookPosition;
var isTraveling = false;
var tempMatrix = new THREE.Matrix4();
var teleportController = null;

function getMarker() {
    var group = new THREE.Group();
    group.name = "navMarker";

    var geometry = new THREE.CircleGeometry(0.2, 32);
    geometry.vertices.shift(); // delete center point
    var material = new THREE.LineBasicMaterial({ color: 0xffffff, toneMapped: false });
    var circle = new THREE.LineLoop(geometry, material);
    group.add(circle);

    var dotGeometry = new THREE.CircleGeometry(0.03, 16);
    var dotMaterial = new THREE.MeshBasicMaterial({
        color: 0xffae00,
        side: THREE.DoubleSide,
        toneMapped: false,
    });
    var dot = new THREE.Mesh(dotGeometry, dotMaterial);

    group.rotation.set(THREE.MathUtils.degToRad(-90), 0, 0);

    group.add(dot);

    return group;
}

async function getNavMesh() {
    var group = await loadOBJ("/navmesh.obj");
    var mesh = group.children[0];
    group.remove(mesh);
    //mesh.layers.enable(5); //raycasting
    mesh.position.set(0, -0.01, 0);

    return mesh;
}

function toggleVisibility(visible) {
    if (marker) {
        if (marker.visible != visible) marker.visible = visible;
    }
}

function onMouseMove(event) {
    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components

    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    if (intersection == null) {
        toggleVisibility(false);
        if (!isGrabbing()) showGrabCursor();
        if (isOn === "artifact") showPointerCursor();
        if (isOn === "blobDough") showPointerCursor();
    } else {
        if (!isTraveling) toggleVisibility(true);
        if (!isGrabbing()) showPointerCursor();
    }
}

function getLookPosition() {
    var rot = getCurrentCameraRotation();
    if (!rot.yawObject || !rot.pitchObject) return; // lookcontrols not yet initialized
    var y = rot.yawObject.rotation.y;
    var x = rot.pitchObject.rotation.z;
    var value = `${x}_${y}`;
    return value;
}

function onMouseDown(event) {
    if (!intersection) return;
    if (state.getUIOpened()) return;

    event.stopPropagation();

    beginLookPosition = getLookPosition();
}

const onControllerSelectStart = (controllerClicked) => (event) => {
    if (!state.getVrActive()) return;

    teleportController = controllerClicked;
};

const onControllerSelectEnd = (event) => {
    if (intersection) {
        var dolly = state.getDolly();

        dolly.position.set(intersection.point.x, 0, intersection.point.z);
    }
};

function onMouseClick(event) {
    if (state.getVrActive()) return;

    if (getLookPosition() != beginLookPosition) {
        // rotated movement using mouse, do not teleport
        return;
    }
    if (intersection) {
        var dolly = state.getDolly();
        toggleVisibility(false);
        isTraveling = true;
        console.log("mouse clicked", event, intersection.point);
        anime({
            targets: dolly.position,
            duration: 1500,
            x: intersection.point.x,
            z: intersection.point.z,
            easing: "easeInOutCubic",
            complete: () => {
                isTraveling = false;
                toggleVisibility(true);
            },
        });
    }
}

async function initializeTeleport(renderer, camera, scene) {
    cam = camera;

    navMesh = await getNavMesh();
    navMeshArray = [navMesh];
    scene.add(navMesh);

    navMesh.matrixAutoUpdate = false;
    navMesh.updateMatrix();

    if (!isMobile()) {
        marker = getMarker();
        scene.add(marker);
        marker.position.set(-1, 0.01, 1);
    }

    raycaster = new THREE.Raycaster();
    //raycaster.layers.set(5);
    mouse = new THREE.Vector2();

    var domEl = renderer.domElement;

    domEl.addEventListener("mousemove", onMouseMove);
    domEl.addEventListener("click", onMouseClick);
    domEl.addEventListener("mousedown", onMouseDown);
}

function bindTeleportControllerEvents() {
    controller1.addEventListener("selectstart", onControllerSelectStart(controller1));
    controller2.addEventListener("selectstart", onControllerSelectStart(controller2));
    controller1.addEventListener("selectend", onControllerSelectEnd);
    controller2.addEventListener("selectend", onControllerSelectEnd);

    teleportController = controller2;
}

function teleportRaycasting(camera, isVr) {
    if (!raycaster) return;

    // setup raycaster depending on type of env
    if (isVr) {
        if (teleportController) {
            tempMatrix.identity().extractRotation(teleportController.matrixWorld);

            raycaster.ray.origin.setFromMatrixPosition(teleportController.matrixWorld);
            raycaster.ray.direction.set(0, 0, -1).applyMatrix4(tempMatrix);
        }
    } else {
        raycaster.setFromCamera(mouse, cam);
        var foundArtifact = checkArtifacts("test", raycaster);

        if (!foundArtifact) {
            if (isOn) {
                if (isOn === "artifact") showPointerCursorDefined("none");
            }
        }

        var foundBlob = checkBlobs("test", raycaster);

        if (!foundBlob) {
            if (isOn) {
                if (isOn === "blobDough") showPointerCursorDefined("none");
            }
        }
    }

    // use raycaster
    var intersects = raycaster.intersectObjects(navMeshArray);
    if (intersects.length) {
        intersection = intersects[0];

        if (marker) {
            marker.position.x = intersection.point.x;
            marker.position.z = intersection.point.z;
        }
    } else {
        intersection = null;
    }
}

export { initializeTeleport, teleportRaycasting, bindTeleportControllerEvents };
