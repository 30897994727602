import anime from "animejs/lib/anime.es.js";
import { getCurrentCameraRotation, updateOrientation } from "../lookControls";
import { isMobile } from "./device";
import { state } from "./state";
import TWEEN from "@tweenjs/tween.js";

function moveBlob(blob, cameraMarker, doAnimation) {
    return new Promise((resolve, reject) => {
        // move the blob towards the camera marker of the fridge

        var originalPosition = blob.position.clone();
        blob.meta.originalPosition = originalPosition;

        // position in front of the camera left
        var xPos = (window.innerWidth / window.innerHeight) * 0.45 * -0.2;

        // position top half of the screen
        var yPos = (window.innerHeight / window.innerWidth) * 0.36 * 0.2;

        // also set the text above the blob, and animate it in
        //document.getElementById("blob-title").setAttribute("position", `${xPos} 0.17 -0.35`);

        // document.getElementById("blob-title").object3D.visible = true;
        // setTimeout(() => {
        //     document
        //         .getElementById("blob-title")
        //         .setAttribute(
        //             "animation__opacity",
        //             "property: opacity; to: 1; dur: 500; easing: linear"
        //         );
        // });
        var worldPosition = new THREE.Vector3();
        if (state.getVrActive()) {
            worldPosition.copy(cameraMarker.position);
            if (blob.meta.fridge.kind == "right") worldPosition.x += 1.3;
            else worldPosition.x -= 1.3;
        } else {
            var cameraLocalPosition = isMobile()
                ? new THREE.Vector3(0, yPos, -0.55)
                : new THREE.Vector3(xPos, 0, -0.35);
            cameraMarker.updateWorldMatrix();
            worldPosition = cameraMarker.localToWorld(cameraLocalPosition);
        }
        if (doAnimation) {
            // for some reason animeJS doesn't work in VR. TweenJS does.
            var duration = state.getVrActive() ? 700 : 1600; // in VR not that far away so move faster
            new TWEEN.Tween(blob.position)
                .to({ x: worldPosition.x, y: worldPosition.y, z: worldPosition.z })
                .easing(TWEEN.Easing.Quadratic.Out)
                .duration(duration)
                .onComplete(resolve)
                .start();
        } else {
            blob.position.x = worldPosition.x;
            blob.position.y = worldPosition.y;
            blob.position.z = worldPosition.z;

            resolve();
        }
    });
}

function moveAndRotateCamera(marker) {
    const dolly = state.getDolly();
    const pos = marker.position;
    const rot = marker.rotation;

    // move camera dolly, but rotate camera
    anime({
        targets: dolly.position,
        duration: 1000,
        x: pos.x,
        z: pos.z,
        easing: "easeOutQuad",
    });

    var tmp = getCurrentCameraRotation();

    let yawObject = tmp.yawObject;
    let pitchObject = tmp.pitchObject;

    let degreesSource = (yawObject.rotation.y * 180) / Math.PI;
    let degreesTarget = (rot.y * 180) / Math.PI;
    let howManyTurns = Math.floor(Math.abs(degreesSource) / 360);
    degreesTarget = degreesTarget + howManyTurns * 360;
    let angle = ((degreesTarget - degreesSource + 540) % 360) - 180;
    let rad = ((degreesSource + angle) * Math.PI) / 180;

    anime({
        targets: yawObject.rotation,
        duration: 900,
        y: rad, // radians
        easing: "linear",
        update: updateOrientation,
    });
    anime({
        targets: pitchObject.rotation,
        duration: 500,
        x: 0, // radians
        easing: "linear",
        update: updateOrientation,
    });
}

function moveBlobBack(blob) {
    return new Promise((resolve, reject) => {
        new TWEEN.Tween(blob.position)
            .to({
                x: blob.meta.originalPosition.x,
                y: blob.meta.originalPosition.y,
                z: blob.meta.originalPosition.z,
            })
            .easing(TWEEN.Easing.Quadratic.Out)
            .duration(1600)
            .onComplete(resolve)
            .start();
    });
}

export { moveAndRotateCamera, moveBlob, moveBlobBack };
