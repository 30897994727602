import objectFitImages from "object-fit-images";
import objectFitVideos from "object-fit-videos";
import { initializeMuseum } from "./museum";
import initializeIntro from "./common/intro";
import "../sass/site.scss";
import { initializeVideo } from "./video";
import initializeVideoControls from "./common/video-controls";

objectFitImages();
objectFitVideos();

initializeMuseum();
initializeIntro();
initializeVideo();
initializeVideoControls();
