function showNonVrUiElements() {
    Array.from(document.querySelectorAll(".ui-element-nonvr")).forEach(x => {
        x.classList.add("ui-element-nonvr--visible");
    });
}

function hideNonVrUiElements() {
    Array.from(document.querySelectorAll(".ui-element-nonvr")).forEach(x => {
        x.classList.remove("ui-element-nonvr--visible");
    });
}

function closeNonVrWindow() {
    return new Promise((resolve, reject) => {
        const panel = document.getElementById("panel-nonvr");
        panel.classList.remove("panel-nonvr--active");
        setTimeout(() => {
            panel.classList.remove("panel-nonvr--visible");
            resolve();
        }, 500);
    });
}

function hideBlobTitle() {
    return new Promise(resolve => {
        document
            .getElementById("blob-title")
            .setAttribute(
                "animation__opacity",
                "property: opacity; to: 0; dur: 500; easing: linear"
            );
        setTimeout(() => {
            document.getElementById("blob-title").object3D.visible = false;
            resolve();
        }, 500);
    });
}

export { showNonVrUiElements, hideNonVrUiElements, closeNonVrWindow };
