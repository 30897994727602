import * as THREE from "three";
import createTextGeometry from "three-bmfont-text";
import sansa from "../fonts/sansa-pro";
import sourcesanspro from "../fonts/sourcesanspro";
import { addFrustumCullingMesh } from "./frustum-culling";
import { getSansaProMaterial, getSourceSansProMaterial } from "./fonts";
import { showPointerCursor, showPointerCursorDefined } from "./cursors";

var raycaster,
    mouse,
    intersection,
    meshes,
    rectMaterial,
    textMaterial,
    isHover = false;
const center = new THREE.Vector3();

function createButton(scene, box) {
    var shape = new THREE.Shape();

    var w = box.x + 0.08; // base size on text
    var h = 0.09;
    (function roundedRect(ctx, x, y, width, height, radius) {
        ctx.moveTo(x, y + radius);
        ctx.lineTo(x, y + height - radius);
        ctx.quadraticCurveTo(x, y + height, x + radius, y + height);
        ctx.lineTo(x + width - radius, y + height);
        ctx.quadraticCurveTo(x + width, y + height, x + width, y + height - radius);
        ctx.lineTo(x + width, y + radius);
        ctx.quadraticCurveTo(x + width, y, x + width - radius, y);
        ctx.lineTo(x + radius, y);
        ctx.quadraticCurveTo(x, y, x, y + radius);
    })(shape, -w / 2, 0, w, h, h / 2);

    var geometry = new THREE.ShapeBufferGeometry(shape);
    rectMaterial = new THREE.MeshBasicMaterial({
        side: THREE.FrontSide,
        color: 0xb8b8b8,
        name: "tv_button_plane",
        toneMapped: false,
    });
    var mesh = new THREE.Mesh(geometry, rectMaterial);
    mesh.name = "tv_button_plane";
    mesh.frustumCulled = false;
    addFrustumCullingMesh(mesh);
    mesh.position.set(0, 1.02, 6.43);
    mesh.rotation.set(0, THREE.MathUtils.degToRad(180), 0);

    meshes = [mesh];

    scene.add(mesh);

    return mesh;
}

async function createText(scene) {
    var geometry = createTextGeometry({
        text: window.tvLink.text,
        font: window.fallbackFont ? sourcesanspro : sansa,
        align: "left",
        flipY: true,
    });
    textMaterial = window.fallbackFont
        ? await getSourceSansProMaterial(0x000000)
        : await getSansaProMaterial(0x000000);

    var layout = geometry.layout;
    var mesh = new THREE.Mesh(geometry, textMaterial);
    mesh.frustumCulled = false;
    addFrustumCullingMesh(mesh);
    //mesh.layers.toggle(1);

    mesh.position.y = 0;
    mesh.position.z = 0;
    mesh.position.x = (-1 * layout.width) / 2;

    var group = new THREE.Group();
    group.add(mesh);
    const scale = 0.0009;
    group.scale.set(scale, -scale, scale);

    group.name = "tv_button_text";

    group.position.set(0, 1.06, 6.42);
    group.rotation.set(0, THREE.MathUtils.degToRad(180), 0);

    scene.add(group);

    var box = new THREE.Box3().setFromObject(group);
    return box.getSize(center);
}

function changeColor(color, colorText, hovered) {
    if (hovered != isHover) {
        rectMaterial.color.set(color);
        rectMaterial.needsUpdate = true;

        textMaterial.uniforms.color.value.set(colorText);

        isHover = hovered;
    }
}

function onMouseMove(e) {
    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components

    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    if (intersection == null) {
        changeColor(0xffffff, 0x000000, false);
    } else {
        changeColor(0x000000, 0xffffff, true);
        showPointerCursorDefined("tvlinkdough");
    }
}

function onClick(e) {
    if (intersection && window.tvLink) {
        window.open(window.tvLink.url, "_blank");
    }
}

function onTvLinkRenderTick(camera) {
    if (!raycaster) return;

    raycaster.setFromCamera(mouse, camera);

    // calculate objects intersecting the picking ray
    var intersects = raycaster.intersectObjects(meshes);
    if (intersects.length) {
        intersection = intersects[0];
    } else {
        intersection = null;
    }
}

async function initializeTvLink(scene) {
    if (!window.tvLink) return;

    var box = await createText(scene); // returns bounding box size
    createButton(scene, box);

    raycaster = new THREE.Raycaster();
    //raycaster.layers.set(5);
    mouse = new THREE.Vector2();

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("click", onClick);
}

export { initializeTvLink, onTvLinkRenderTick };
