import * as THREE from "three";
import { getDebugParams } from "../ui/debugUI";
import { loadGLTF, loadHDRCubeTexture } from "../common/asyncLoaders";
import { state } from "../common/state";
import { showPointerCursor, showPointerCursorDefined, resetIsOn } from "../common/cursors";
import { hideHamburgerNavButton, showHamburgerNavButton } from "../ui/hamburger-nav";
import { addFrustumCullingMesh } from "../common/frustum-culling";
import { showArtifactVrUi } from "../ui/ui-artifact-vr";
import { controller1, controller2 } from "../vr-controller";

var raycaster, mouse, intersection, camera;
var tempMatrix = new THREE.Matrix4();
const artifacts = [];
const meshes = [];

const artifactPositions = {
    "1": {
        scale: 1.4,
        tubes: false,
        position: new THREE.Vector3(-1.9, 1.2, 6.2),
        gltf: "/certificate/certificate.gltf",
    },
    "2": {
        scale: 1.35,
        tubes: false,
        position: new THREE.Vector3(-3.21, 1.28, 2.25),
        gltf: "/bread/bread.gltf",
    },
    "3": {
        scale: 2,
        slanted: true,
        tubes: false,
        position: new THREE.Vector3(-3.21, 1.3, -2.25),
        gltf: "/microscope/microscope.gltf",
    },
    "4": {
        scale: 1,
        position: new THREE.Vector3(3.21, 1.3, -2.25),
        gltf: "/tubes/tubes_gltf_uvmoved_003.gltf",
        tubes: true,
    },
    "5": {
        tubes: false,
        scale: 2.2,
        position: new THREE.Vector3(3.21, 1.2, 2.25),
        gltf: "/jar/jar.gltf",
    },
    "6": {
        scale: 1.4,
        slanted: true,
        tubes: false,
        position: new THREE.Vector3(1.91, 1.28, 6.2),
        gltf: "/stamp/stamp.gltf",
    },
};

const hdrsLeft = [
    "/envmaps/pedestals-left/item01_posx.hdr",
    "/envmaps/pedestals-left/item01_negx.hdr",
    "/envmaps/pedestals-left/item01_posy.hdr",
    "/envmaps/pedestals-left/item01_negy.hdr",
    "/envmaps/pedestals-left/item01_posz.hdr",
    "/envmaps/pedestals-left/item01_negz.hdr",
];

const hdrsRight = [
    "/envmaps/pedestals-right/item02_posx.hdr",
    "/envmaps/pedestals-right/item02_negx.hdr",
    "/envmaps/pedestals-right/item02_posy.hdr",
    "/envmaps/pedestals-right/item02_negy.hdr",
    "/envmaps/pedestals-right/item02_posz.hdr",
    "/envmaps/pedestals-right/item02_negz.hdr",
];

function setUI(meta) {
    document.getElementById("artifact-image").src = "";
    document.getElementById("artifact-title").innerHTML = "";
    document.getElementById("artifact-description").innerHTML = "";
    setTimeout(() => {
        document.getElementById("artifact-title").innerHTML = meta.title;
        document.getElementById("artifact-description").innerHTML = meta.description;
        document.getElementById("artifact-image").src = meta.image;
    });
}

function checkArtifacts(mode, raycast) {
    var intersects = raycast.intersectObjects(meshes);
    if (intersects.length) {
        showPointerCursorDefined("artifact");
        return true;
    } else {
        intersection = null;
    }

    resetIsOn();

    return false;
}

function onClick(event) {
    var t = performance.now() - window.lastLookedAround;
    if (t < 300) {
        // just looked around. do nothing
        return;
    }

    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    raycaster.setFromCamera(mouse, camera);

    // calculate objects intersecting the picking ray
    var intersects = raycaster.intersectObjects(meshes);

    if (intersects.length) {
        intersection = intersects[0];
    } else {
        intersection = null;
    }

    testIntersection(intersection);
}

function testIntersection(intersection) {
    if (!intersection) return;

    if (state.getUIOpened()) return;

    var artifact = intersection.object;
    var meta = artifact.meta;

    if (state.getVrActive()) {
        var pos = artifactPositions[meta.number.toString()];
        showArtifactVrUi(meta, artifact, pos);
    } else {
        setUI(meta);

        hideHamburgerNavButton();

        setTimeout(() => {
            document
                .getElementById("panel-artifact")
                .classList.remove("panel-artifactwindow--hidden");
        });

        state.setUIOpened(true);
    }
}

async function addTubes(renderer, scene, item) {}

async function addArtifact(renderer, scene, item) {
    var pos = artifactPositions[item.number.toString()];

    var debugParams = getDebugParams();
    var gltf = await loadGLTF(pos.gltf);
    var hdrs = item.number >= 4 ? hdrsRight : hdrsLeft;
    var envMap = await loadHDRCubeTexture(renderer, hdrs, false);

    //texture.encoding = THREE.sRGBEncoding;
    //    texture.flipY = false;

    var mesh = new THREE.Group();
    mesh.add(...gltf.scene.children);

    mesh.position.copy(pos.position);
    mesh.scale.set(pos.scale, pos.scale, pos.scale);
    mesh.rotation.order = "YXZ";
    if (pos.slanted === true) {
        mesh.rotation.set(THREE.MathUtils.degToRad(45), 0, 0);
    }
    if (pos.tubes) {
        mesh.rotation.set(0, THREE.MathUtils.degToRad(-90), 0);
    }

    mesh.traverse((node) => {
        node.frustumCulled = false;
        addFrustumCullingMesh(node);
        if (node.isMesh) {
            node.meta = item;
            meshes.push(node);
        }
        if (node.material) {
            node.material.magFilter = THREE.NearestFilter;
            node.material.minFilter = THREE.NearestFilter;
            //node.material.anisotropy = renderer.capabilities.getMaxAnisotropy();
        }
        if (node.material && "envMap" in node.material) {
            node.material.envMap = envMap;
            node.material.envMapIntensity = debugParams.envMapIntensity;

            node.material.needsUpdate = true;
        }

        // tubes video
        if (pos.tubes && node.name == "frame_low001.001_1") {
            var video = document.getElementById("video-tubes");

            //    var geometry = new THREE.BoxGeometry(1, 1, 1);
            var texture = new THREE.VideoTexture(video);
            texture.name = "Video_tubes_texture";

            var material = new THREE.MeshBasicMaterial({
                map: texture,
                flatShading: true,
                toneMapped: false,
                name: "Video_Tubes",
            });

            node.material = material;
            node.material.needsUpdate = true;

            video.play();
        }
    });
    mesh.meta = item;
    mesh.meta.tubes = pos.tubes;
    mesh.name = "artifact" + item.number;
    scene.add(mesh);

    return mesh;
}

async function initializeArtifacts(renderer, scene, cameraParam) {
    camera = cameraParam;

    for (var item of state.getAllArtifacts()) {
        if (!item.tubes) {
            var mesh = await addArtifact(renderer, scene, item);
            scene.add(mesh);
            artifacts.push(mesh);
        } else {
            var mesh = await addTubes(renderer, scene, item);
            scene.add(mesh);
            artifacts.push(mesh);
        }
    }

    raycaster = new THREE.Raycaster();
    mouse = new THREE.Vector2();

    document.addEventListener("click", onClick, false);

    document.getElementById("button-close-artifact").addEventListener("click", (e) => {
        e.preventDefault();
        e.stopImmediatePropagation();
        document.getElementById("panel-artifact").classList.add("panel-artifactwindow--hidden");

        showHamburgerNavButton();

        state.setUIOpened(false);
    });
}

function initializeArtifactControllers() {
    controller1.addEventListener("selectend", onControllerClick);
    controller2.addEventListener("selectend", onControllerClick);
}

function onControllerClick(event) {
    if (!state.getVrActive()) return;

    testControllerRayCast(controller1);
    testControllerRayCast(controller2);
}

function testControllerRayCast(controller) {
    tempMatrix.identity().extractRotation(controller.matrixWorld);

    raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
    raycaster.ray.direction.set(0, 0, -1).applyMatrix4(tempMatrix);

    // calculate objects intersecting the picking ray
    var intersects = raycaster.intersectObjects(meshes);

    var intersection = null;
    if (intersects.length) {
        intersection = intersects[0];
    }

    testIntersection(intersection);
}

function onArtifactRenderTick(camera) {
    for (var i = 0; i < artifacts.length; i++) {
        if (!artifacts[i].meta.tubes) artifacts[i].rotation.y -= 0.005;
    }
}

export { initializeArtifacts, onArtifactRenderTick, checkArtifacts, initializeArtifactControllers };
