import * as THREE from "three";
import { loadRGBE } from "../common/asyncLoaders";

async function addSky(scene) {
    var texture = await loadRGBE("/sky.hdr");

    var geometry = new THREE.SphereGeometry(1000, 32, 32);
    var material = new THREE.MeshBasicMaterial({
        map: texture,
        fog: false,
        side: THREE.BackSide,
        color: 0xffffff
    });
    var sphere = new THREE.Mesh(geometry, material);
    sphere.position.set(0, 40, 0);
    sphere.rotation.order = "YXZ";
    sphere.rotation.set(0, THREE.MathUtils.degToRad(90), 0);
    sphere.name = "sky";

    scene.add(sphere);

    return sphere;
}

export { addSky };
