import { stringify } from "query-string";
import { state } from "./state";

function updateUrl(url, title) {
    if (state.getVrActive()) return;

    var params = stringify(window.queryParams);
    if (params) params = "?" + params;
    window.history.pushState({}, title, url + params);
}

export { updateUrl };
