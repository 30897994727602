import * as THREE from "three";

// fridge positon is the position of the text above it

const fridgePositions = {
    europe: {
        position: new THREE.Vector3(3.49, 2.171, 4.496),
        r_y: -90,
        kind: "right"
    },
    australia: {
        position: new THREE.Vector3(3.49, 2.171, 0.007),
        r_y: -90,
        kind: "right"
    },
    asia: {
        position: new THREE.Vector3(3.49, 2.171, -4.524),
        r_y: -90,
        kind: "right"
    },
    africa: {
        position: new THREE.Vector3(-3.49, 2.171, 4.496),
        r_y: 90,
        kind: "left"
    },
    "north-america": {
        position: new THREE.Vector3(-3.49, 2.171, -0.007),
        r_y: 90,
        kind: "left"
    },
    "south-america": {
        position: new THREE.Vector3(-3.49, 2.171, -4.524),
        r_y: 90,
        kind: "left"
    }
};

export { fridgePositions };
