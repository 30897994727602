import * as THREE from "three";

import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
// import { SMAAPass } from "three/examples/jsm/postprocessing/SMAAPass";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader";
// import { VerticalBlurShader } from "three/examples/jsm/shaders/VerticalBlurShader";
// import { HorizontalBlurShader } from "three/examples/jsm/shaders/HorizontalBlurShader";
import { getDebugParams, addDebugListener } from "./ui/debugUI";
import { getPerformanceParameters } from "./common/performance";

function createPasses(renderer, scene, camera) {
    var fxaaPass, bloomPass;
    var params = getDebugParams();
    var pixelRatio = renderer.getPixelRatio();
    var result = [];

    // Render pass

    var renderPass = new RenderPass(scene, camera);
    result.push(renderPass);

    // Bloom
    bloomPass = new UnrealBloomPass(
        new THREE.Vector2(window.innerWidth, window.innerHeight),
        params.bloomStrength,
        params.bloomRadius,
        params.bloomThreshold
    );

    addDebugListener("postprocessing", "bloomThreshold", 0.0, 1.0, 0.01, (params) => {
        bloomPass.threshold = params.bloomThreshold;
    });
    addDebugListener("postprocessing", "bloomStrength", 0.0, 3.0, 0.01, (params) => {
        bloomPass.strength = params.bloomStrength;
    });
    addDebugListener("postprocessing", "bloomRadius", 0.0, 1.0, 0.01, (params) => {
        bloomPass.radius = params.bloomRadius;
    });

    result.push(bloomPass);
   
    // FXAA

    if (getPerformanceParameters().antialias) {
        fxaaPass = new ShaderPass(FXAAShader);
        fxaaPass.material.uniforms["resolution"].value.x = 1 / (window.innerWidth * pixelRatio);
        fxaaPass.material.uniforms["resolution"].value.y = 1 / (window.innerHeight * pixelRatio);
        result.push(fxaaPass);
    }

    window.addEventListener("resize", function () {
        if (bloomPass)
            bloomPass.resolution = new THREE.Vector2(window.innerWidth, window.innerHeight);
        if (fxaaPass) {
            fxaaPass.material.uniforms["resolution"].value.x = 1 / (window.innerWidth * pixelRatio);
            fxaaPass.material.uniforms["resolution"].value.y =
                1 / (window.innerHeight * pixelRatio);
        }

        console.log("Postprocessing resized");
    });

    if (getPerformanceParameters().reversepp) {
        result.reverse();
    }

    // SMAA

    // var smaaPass = new SMAAPass(window.innerWidth * pixelRatio, window.innerHeight * pixelRatio);

    // Blur

    // var hblurPass = new ShaderPass(HorizontalBlurShader);
    // var vblurPass = new ShaderPass(VerticalBlurShader);
    // hblurPass.uniforms["h"].value = 3 / (window.innerWidth / 3);
    // vblurPass.uniforms["v"].value = 3 / (window.innerHeight / 3);
    // hblurPass.uniforms["tDiffuse"].value = 20;
    // vblurPass.uniforms["tDiffuse"].value = 20;

    // Final pass

    //var finalPass;
    // var finalPass = new ShaderPass(
    //     new THREE.ShaderMaterial({
    //         uniforms: {
    //             baseTexture: { value: null },
    //             bloomTexture: { value: bloomComposer.renderTarget2.texture }
    //         },
    //         vertexShader: `varying vec2 vUv;

    // 		void main() {

    // 			vUv = uv;

    // 			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

    // 		}`,
    //         fragmentShader: `uniform sampler2D baseTexture;
    // 		uniform sampler2D bloomTexture;

    // 		varying vec2 vUv;

    // 		vec4 getTexture( sampler2D texelToLinearTexture ) {

    // 			return mapTexelToLinear( texture2D( texelToLinearTexture , vUv ) );

    // 		}

    // 		void main() {

    // 			gl_FragColor = ( getTexture( baseTexture ) + vec4( 1.0 ) * getTexture( bloomTexture ) );

    // 		}`,
    //         defines: {}
    //     }),
    //     "baseTexture"
    // );
    // finalPass.needsSwap = true;

    return result;
}

export { createPasses };
