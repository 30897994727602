import { GUI } from "dat.gui";
import * as THREE from "three";
import { getPerformanceParameters } from "../common/performance";

const callbacks = {};
var gui, folders;

const params = {
    toneMapping: "Reinhard",
    toneMappingValue: (toneMapping) => {
        return toneMappingOptions[toneMapping];
    },
    toneMappingWhitePoint: 1.4,
    toneMappingExposure: 1.05,
    lightMapIntensity: 8,
    lightMapIntensityFridges: 1.4,
    bloomStrength: 0.23,
    bloomThreshold: 0.05,
    bloomRadius: 0.17,
    envMapIntensity: 1.7,
    sepiaAmount: 0.15,
    fogNear: 0.6,
    fogFar: 138,
    fogColor: 0xc4c292,
};

const toneMappingOptions = {
    None: THREE.NoToneMapping,
    Linear: THREE.LinearToneMapping,
    Reinhard: THREE.ReinhardToneMapping,
    Uncharted2: THREE.Uncharted2ToneMapping,
    Cineon: THREE.CineonToneMapping,
    ACESFilmic: THREE.ACESFilmicToneMapping,
};

function getDebugParams() {
    return params;
}

function addDebugListener(folder, paramName, min, max, step, callback) {
    if (!getPerformanceParameters().debug) return;
    var parent = gui;
    if (folder) {
        parent = folders[folder];
        if (parent == null) {
            throw Error("Folder doesn't exist: " + folder);
        }
    }
    parent
        .add(params, paramName, min, max)
        .step(step)
        .onChange(function () {
            var callback = callbacks[paramName];

            if (callback) {
                callback(params);
            }
        });
    callbacks[paramName] = callback;
}

function addDebugListenerColor(folder, paramName, callback) {
    if (!getPerformanceParameters().debug) return;
    var parent = gui;
    if (folder) {
        parent = folders[folder];
        if (parent == null) {
            throw Error("Folder doesn't exist: " + folder);
        }
    }
    parent.addColor(params, paramName).onChange(function () {
        var callback = callbacks[paramName];

        if (callback) {
            callback(params);
        }
    });
    callbacks[paramName] = callback;
}

function addDebugTonemapping(callback) {
    if (!getPerformanceParameters().debug) return;

    folders["tonemapping"]
        .add(params, "toneMapping", Object.keys(toneMappingOptions))
        .onChange(function () {
            var callback = callbacks["toneMapping"];

            if (callback) {
                callback(toneMappingOptions[params.toneMapping]);
            }
        });
    callbacks["toneMapping"] = callback;
}

function addDebugGui() {
    if (!getPerformanceParameters().debug) return;

    gui = new GUI();

    folders = {
        lightmaps: gui.addFolder("lightmaps"),
        fog: gui.addFolder("fog"),
        envMaps: gui.addFolder("envMaps"),
        postprocessing: gui.addFolder("postprocessing"),
        tonemapping: gui.addFolder("tonemapping"),
    };
}

export {
    getDebugParams,
    addDebugListener,
    addDebugGui,
    addDebugTonemapping,
    addDebugListenerColor,
};
