function isWebXrEnabled() {
    return new Promise(function (resolve) {
        if ("xr" in navigator) {
            navigator.xr.isSessionSupported("immersive-vr").then(function (supported) {
                resolve(supported);
            });
        } else {
            resolve(false);
        }
    });
}

export { isWebXrEnabled };
