import * as THREE from "three";

import { parseUrl } from "query-string";
import tippy from "tippy.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import Stats from "stats.js";
import { loadRoom } from "./model/room";
import TWEEN from "@tweenjs/tween.js";
import {
    initializeBlobs,
    onBlobsRenderTick,
    tryOpenDefaultDough,
    gatherVisibleDoughs,
    bindBlobsControllerEvents,
} from "./model/blobs";
import { initLookControls, updateOrientation } from "./lookControls";
import {
    addDebugGui,
    addDebugListener,
    getDebugParams,
    addDebugTonemapping,
    addDebugListenerColor,
} from "./ui/debugUI";
import { VRButton } from "./vr-button";
import { addSky } from "./model/sky";
import { initializeTeleport, teleportRaycasting, bindTeleportControllerEvents } from "./teleport";
//import { htmlEmbedRaycasting, bindHtmlEmbedControllerEvents } from "./htmlembed";
import { createPasses } from "./postprocessing";
import { preloadAll } from "./common/preloadingManager";
import { initializeArtifacts, onArtifactRenderTick, initializeArtifactControllers } from "./model/artifacts";
import { state } from "./common/state";
import { initializeSound } from "./common/sound";
import { initializeTvLink, onTvLinkRenderTick } from "./common/tv-link";
import { initializeFridgeText, rotateSmoke } from "./model/fridge-titles";
import { isMobile } from "./common/device";
import { initializeHamburgerNav, showHamburgerNavButton } from "./ui/hamburger-nav";
import { onParticlesRenderTick, initializeParticles } from "./common/particles2";
import { initializeCursors } from "./common/cursors";
import { initializeLensflare } from "./common/lensflare";
import { showRoomNav, initializeRoomNav, initializeRoomNavSearch } from "./common/room-nav";
import { initializeDropups } from "./common/button-nav";
import request from "./common/superagent";
import { getPerformanceParameters } from "./common/performance";
import { enableFrustumCulling } from "./common/frustum-culling";
import { initializeBlobNav } from "./common/blob-nav";
import { initializeControllers } from "./vr-controller";
import { initializeUiVr } from "./ui/ui-vr";
import { isWebXrEnabled } from "./web-xr";
import { LAYER_HIDEVR } from "./common/constants";
import { initializeArtifactUiVr } from "./ui/ui-artifact-vr";

window.queryParams = parseUrl(window.location.href).query;
var performanceParams = getPerformanceParameters();

var lastCalledTime;
var counter = 0;
var fps;

var oldTonemappingExposure;

var clock = new THREE.Clock();
var container, stats, camera, scene, renderer, dolly;
var finalComposer;

var enablePostProcessing = getPerformanceParameters().postprocessing;
var enableParticles = getPerformanceParameters().particles;

function render() {
    //var dt = clock.getDelta();

    var isVr = state.getVrActive();

    updateOrientation();
    teleportRaycasting(camera, isVr);
    onBlobsRenderTick(isVr);
    if (!isVr) rotateSmoke();
    onTvLinkRenderTick(camera);
    onArtifactRenderTick(camera);
    if (enableParticles && !isVr) onParticlesRenderTick(camera);

    if (enablePostProcessing && !isVr) {
        finalComposer.render();
    } else renderer.render(scene, camera);
}

function animate() {
    renderer.setAnimationLoop(() => {
        render();

        TWEEN.update();

        // counter++;

        // if (!lastCalledTime) {
        //     lastCalledTime = performance.now();
        //     fps = 0;
        //     return;
        // }
        // var delta = (performance.now() - lastCalledTime) / 1000;
        // lastCalledTime = performance.now();
        // fps = 1 / delta;
        // if (counter > 200) {
        //     counter = 0;
        //     console.log("FPS", fps);
        // }

        if (performanceParams.debug) {
            stats.update();
        }
    });
}

function onWindowResize() {
    var width = window.innerWidth;
    var height = window.innerHeight;

    camera.aspect = width / height;
    camera.updateProjectionMatrix();

    if (renderer) renderer.setSize(width, height);
    if (enablePostProcessing && !state.getVrActive()) {
        if (finalComposer) finalComposer.setSize(width, height);

        console.log("RESIZE", finalComposer, renderer, width);
    }
}

function addPostprocessing(renderer, scene, camera) {
    finalComposer = new EffectComposer(renderer);

    var passes = createPasses(renderer, scene, camera);

    for (var pass of passes) {
        finalComposer.addPass(pass);
    }

    finalComposer.setSize(window.innerWidth, window.innerHeight);
}

function addLight() {
    var studioLightLeft = new THREE.DirectionalLight(0xffffff, 2.0);

    studioLightLeft.name = "Studio-LightLeft";
    studioLightLeft.position.set(0, 100, -10);

    //scene.add(studioLightLeft);

    var studioLightCenter = new THREE.DirectionalLight(0xffffff, 1.5);

    studioLightCenter.name = "Studio-LightLeft";
    studioLightCenter.position.set(0, -2, 2);

    var helper = new THREE.DirectionalLightHelper(studioLightCenter, 5);
    //scene.add( helper );
    //scene.add( studioLightCenter );
}

function setupRenderer() {
    var params = getDebugParams();

    renderer = new THREE.WebGLRenderer({
        stencil: false,
        antialias: true, // no way of dynamically changing AA in threejs, so needs to be on
        powerPreference: "high-performance",
        preserveDrawingBuffer: false,
    });
    var pixelRatio = getPerformanceParameters().pixelratio ? window.devicePixelRatio : 1;
    console.log("Pixel ratio", pixelRatio);
    if (pixelRatio >= 2) pixelRatio = 1.45;
    renderer.setPixelRatio(pixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.logarithmicDepthBuffer = false;
    renderer.physicallyCorrectLights = true;
    renderer.toneMapping = params.toneMappingValue(params.toneMapping);
    renderer.toneMappingExposure = params.toneMappingExposure;
    renderer.toneMappingWhitePoint = params.toneMappingWhitePoint;
    renderer.outputEncoding = THREE.sRGBEncoding; // same as gamma factor 2.2
    renderer.xr.enabled = true;

    state.setRenderer(renderer);
}

function setupScene() {
    scene = new THREE.Scene();
    scene.name = "Main Scene";
    console.log("sID", scene.id);
    scene.background = new THREE.Color(0x000000);

    state.setScene(scene);
}

function setupCamera() {
    camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight);
    camera.position.set(0, 1.65, 0);
    camera.lookAt(new THREE.Vector3(0, 1.65, -1));
    camera.layers.enable(LAYER_HIDEVR);

    dolly = new THREE.Group();
    dolly.position.set(0, 0, 3);
    dolly.add(camera);

    state.setDolly(dolly);
    state.setCamera(camera);
}

function addDebuggingOutput() {
    addDebugGui();
    stats = new Stats();
    container.appendChild(stats.dom);
}

async function initializeRendering() {
    var params = getDebugParams();
    // create container
    container = document.getElementById("scene-container");

    if (performanceParams.debug) {
        addDebuggingOutput();
    }

    setupCamera();
    setupScene();
    setupRenderer();

    scene.add(dolly);

    // add fog
    if (getPerformanceParameters().fog) {
        scene.fog = new THREE.Fog(params.fogColor, params.fogNear, params.fogFar);

        addDebugListenerColor("fog", "fogColor", (params) => {
            scene.fog.color.set(params.fogColor);
        });
        addDebugListener("fog", "fogNear", 0.0, 10.0, 0.1, (params) => {
            scene.fog.near = params.fogNear;
        });
        addDebugListener("fog", "fogFar", 5, 500, 0.1, (params) => {
            scene.fog.far = params.fogFar;
        });
    }

    addDebugListener("tonemapping", "toneMappingExposure", 0.0, 3.0, 0.01, (params) => {
        renderer.toneMappingExposure = params.toneMappingExposure;
    });
    addDebugListener("tonemapping", "toneMappingWhitePoint", 0.0, 3.0, 0.1, (params) => {
        renderer.toneMappingWhitePoint = params.toneMappingWhitePoint;
    });
    addDebugTonemapping((toneMapping) => {
        console.log("toneMapping", toneMapping);
        renderer.toneMapping = toneMapping;
        scene.traverse((tmp) => {
            if (tmp.material) tmp.material.needsUpdate = true;
        });
    });

    if (enablePostProcessing) {
        addPostprocessing(renderer, scene, camera);
    }

    //addLight();

    await Promise.all([
        addSky(scene),
        loadRoom(renderer, scene),
        initializeBlobs(),
        initializeFridgeText(scene),
        initializeArtifacts(renderer, scene, camera),
        initializeTeleport(renderer, camera, scene),
        initializeLensflare(),
    ]);

    if (enableParticles) initializeParticles(scene);
    container.appendChild(renderer.domElement);

    window.addEventListener("resize", onWindowResize, false);

    initLookControls(renderer, camera);

    animate();

    document.getElementById("percent").innerHTML = "100%";

    setTimeout(() => {
        hidePreloader();
        initializeCursors(renderer);

        document.querySelector(".tutorial__close").classList.remove("tutorial__close--hidden");
        document
            .querySelector(".tutorial__initmessage")
            .classList.add("tutorial__initmessage--hidden");
    });

    // initialize tv link async. when you turn around it will be loaded
    setTimeout(() => {
        initializeTvLink(scene).then(() => {});
        initializeControllers();

        bindTeleportControllerEvents();
        bindBlobsControllerEvents();

        initializeArtifactControllers();
    }, 1200);
}

function tryAddWebXrButton() {
    function create() {
        var vrButton = VRButton.createButton(
            renderer,
            null,
            () => {
                var renderer = state.getRenderer();
                console.log("reconfig renderer");
                oldTonemappingExposure = getDebugParams().toneMappingExposure;
                renderer.toneMappingExposure = 0.26;

                var camera = state.getCamera();
                camera.layers.disable(LAYER_HIDEVR);
            },
            () => {
                var renderer = state.getRenderer();
                renderer.toneMappingExposure = oldTonemappingExposure;
            }
        );
        vrButton.classList.add("vr-button");
        document.body.appendChild(vrButton);
    }

    if (performanceParams.vrbutton) create();

    if (!isMobile()) {
        isWebXrEnabled().then((enabled) => {
            console.log("Webvr enabled", enabled);

            if (enabled) {
                create();
                initializeUiVr();
                initializeArtifactUiVr();
            }
        });
    }

    // initializeArtifactUiVr(); // TODO: remove
    // initializeUiVr(); // TODO: remove
}

function initializeTooltips() {
    tippy("[data-tippy-content]", {
        //hideOnClick: false,
        // trigger: "click",
        placement: "top-start",
        offset: [-15, 22],
    });
}

function showPreloader() {
    document.getElementById("preloader").classList.remove("preloader--hidden");
}

function hidePreloader() {
    document.getElementById("preloader").classList.add("preloader--hidden");
}

function showTutorial() {
    var isMob = isMobile();
    Array.from(document.querySelectorAll("#panel-tutorial .mobile")).forEach((item) => {
        item.style.display = isMob ? "block" : "none";
    });
    Array.from(document.querySelectorAll("#panel-tutorial .desktop")).forEach((item) => {
        item.style.display = isMob ? "none" : "block";
    });
    state.setUIOpened(true);
    document.getElementById("panel-tutorial").classList.remove("tutorial--hidden");
}

function hideTutorial() {
    document.getElementById("panel-tutorial").classList.add("tutorial--hidden");
    state.setUIOpened(false);
}

function showSettings() {
    document.getElementById("settings").classList.add("active");
}

async function initializeMuseum() {
    if (window.pageType != "museum") return;

    showPreloader();

    // load data
    var data = await request.get(window.baseApiUrl + "/api/doughs");
    state.setAllDoughs(data.body);
    state.setAllArtifacts(window.artifacts);

    // must happen before preloader. preloader uses this data
    gatherVisibleDoughs();

    await preloadAll();

    document.body.classList.add("body-overflow");

    state.setOriginalDocumentTitle(document.title);

    initializeDropups();
    initializeHamburgerNav(scene); // hamburger menu
    initializeRoomNav();
    initializeRoomNavSearch();
    initializeBlobNav();

    if (!window.defaultDoughId) {
        // only open tutorial if we're not opening a deep link to a dough
        showTutorial();
    }
    document.getElementById("button-close-tutorial").addEventListener("click", (e) => {
        // after tutorial
        e.preventDefault();
        e.stopImmediatePropagation();
        // if (isMobile()) {
        //     enableDeviceOrientationControls(true);
        // }
        hideTutorial();
        tryAddWebXrButton();
        showHamburgerNavButton();
        showRoomNav(); // country and continent navigation
        // load sound and don't wait for it. Even delay it a bit
        initializeSound(camera);
        showSettings();
    });

    initializeTooltips();

    // rendering pipeline setup
    await initializeRendering();

    // try to open the deeplinked dough
    if (!state.getVrActive()) {
        var hasDefault = tryOpenDefaultDough();
        if (hasDefault) {
            tryAddWebXrButton();
            showSettings();
            initializeSound(camera);
        }
    }

    setTimeout(() => {
        // load doughs per flour type async
        enableFrustumCulling();
    }, 1000);
}

export { initializeMuseum };
