const meshes = [];

function addFrustumCullingMesh(mesh) {
    meshes.push(mesh);
}

function enableFrustumCulling() {
    for (var item of meshes) {
        item.frustumCulled = true;
    }
}

export { addFrustumCullingMesh, enableFrustumCulling };
