import * as THREE from "three";
import { loadOBJ, loadTexture, loadHDRCubeTexture } from "../common/asyncLoaders";
import { state } from "../common/state";
import { noise } from "../common/perlin-noise";
import { openBlobAndShowUI, closeUI, switchBlobs, previousBlob, nextBlob } from "../ui/ui-nonvr";
import { fridgePositions } from "../common/fridge-positions";
import { isMobile } from "../common/device";
import { getPerformanceParameters, getBlobTextures } from "../common/performance";
import { addFrustumCullingMesh } from "../common/frustum-culling";
import { showPointerCursor, showPointerCursorDefined, resetIsOn } from "../common/cursors";
// import anime from "animejs/lib/anime.es.js";
// import { showNonVrUiElements } from "../ui/ui-animations";
// import { LAYER_HIDEVR } from "../common/constants";
import { isOculusBrowser } from "../common/device";
import { controller1, controller2 } from "../vr-controller";
import { showVrBlobScreen, hideVrBlobScreen } from "../ui/ui-vr";
import { moveBlob, moveBlobBack } from "../common/animations";
import { hideVrArtifactUiIfOpen } from "../ui/ui-artifact-vr";

const continents = ["north-america", "south-america", "australia", "europe", "africa", "asia"];

const blobs = []; // raycasting array
var randomBlobMesh;
var blobCounter = 1;
var isOpeningBlob = false;
//var startTime = Date.now();
var blobToHover = null;
var tempMatrix = new THREE.Matrix4();

var raycaster, mouse, intersection, camera, blobToRotate, isDragging, previousMousePosition;

/**
 * @type {THREE.Scene}
 */
var scene;
/**
 * @type {THREE.Renderer}
 */
var renderer;

function shuffle(array) {
    var currentIndex = array.length,
        temporaryValue,
        randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

function addMovementMarker(fridge, slug) {
    const distance = isMobile() ? 0.5 : 1.7;
    var markerPos = new THREE.Vector3(
        fridge.r_y < 0 ? fridge.position.x - distance : fridge.position.x + distance,
        1.65,
        fridge.position.z
    );
    // place movement marker
    var markerObject = new THREE.Object3D();
    markerObject.name = "fridgeMarker_" + slug;
    markerObject.position.copy(markerPos);
    markerObject.rotation.set(0, THREE.MathUtils.degToRad(fridge.r_y), 0);
    scene.add(markerObject);
    fridge.marker = markerObject; // keep marker
}

async function gatherVisibleDoughs() {
    var doughCountPerFridge = getPerformanceParameters().blobcount;

    for (var continent of continents) {
        const continentDoughs = state
            .getAllDoughs()
            .filter((x) => x.continent === continent && x.textureId != null);

        var randomDoughs;
        var defaultDoughToAdd = undefined;
        if (window.defaultDoughId) {
            var defaultDough = state.getAllDoughs().find((x) => x.id == window.defaultDoughId);
            if (defaultDough.continent == continent) {
                // Make sure the deeplinked dough is present in the fridge
                defaultDoughToAdd = defaultDough;
            }
        }
        if (defaultDoughToAdd) {
            var filtered = shuffle(
                continentDoughs.filter((x) => x.id !== window.defaultDoughId)
            ).slice(0, doughCountPerFridge - 1);
            randomDoughs = [defaultDoughToAdd, ...filtered];
        } else {
            randomDoughs = shuffle(continentDoughs).slice(0, doughCountPerFridge);
        }

        state.setVisibleContinentDoughs(continent, randomDoughs);

        state.setContinentDoughs(continent, continentDoughs);
    }
}

async function loadContinent(slug) {
    const continent = window.continents.find((x) => x.slug === slug);
    if (!continent) {
        throw Error("Continent not found: " + slug);
    }
    const fridge = fridgePositions[slug];

    addMovementMarker(fridge, slug);

    var randomDoughs = Array.from(state.getVisibleContinentDoughs(slug));

    if (!randomDoughs.length) return;

    // set x axis start
    var x = 0;
    if (fridge.position.x > 0) x = fridge.position.x + 0.25;
    else x = fridge.position.x - 0.25;

    // set z axis start
    var z = 0;
    if (getPerformanceParameters().blobcount == 3) z = fridge.position.z;
    else z = fridge.position.z - 0.12;

    var y_list = [1.647, 1.16, 0.648];

    var colCount = getPerformanceParameters().blobcount / 3;

    for (let y of y_list) {
        for (let i = 0; i <= colCount - 1; i++) {
            var zz = z + i * 0.27;

            var dough = randomDoughs.pop();
            if (dough) {
                var blobMesh = await addBlob(dough, fridge);
                if (blobMesh) {
                    blobMesh.position.set(x, y, zz);
                    blobMesh.updateMatrix();

                    scene.add(blobMesh);

                    blobs.push(blobMesh);
                }
            }
        }
    }
}

async function initializeBlobs() {
    camera = state.getCamera();
    scene = state.getScene();
    renderer = state.getRenderer();
    var domEl = renderer.domElement;

    await Promise.all(continents.map((x) => loadContinent(x)));

    // raycasting

    raycaster = new THREE.Raycaster();
    mouse = new THREE.Vector2();

    // prevent rotating of blobs when scrolling
    document.getElementById("panel-nonvr").addEventListener("touchstart", function (e) {
        e.stopPropagation();
    });
    document.getElementById("panel-nonvr").addEventListener("mousedown", function (e) {
        e.stopPropagation();
    });

    domEl.addEventListener("mousedown", onMouseDown);
    domEl.addEventListener("mousemove", onMouseMove);
    domEl.addEventListener("mouseup", onMouseUp);
    domEl.addEventListener("touchstart", onTouchStart);
    domEl.addEventListener("touchend", onTouchEnd);
    domEl.addEventListener("touchmove", onTouchMove);
    domEl.addEventListener("click", onMouseClick);

    document.querySelector("#panel-nonvr .close-button").addEventListener("click", (e) => {
        closeUI().then(() => {
            blobToRotate = undefined;
        });
    });

    document.getElementById("prev-button-nonvr").addEventListener("click", (e) => {
        e.preventDefault();
        previousBlob();
    });
    document.getElementById("next-button-nonvr").addEventListener("click", (e) => {
        e.preventDefault();
        nextBlob();
    });

    var fullscreenButton = document.querySelector(".panel-nonvr--default .fullscreen-button");
    if (fullscreenButton) {
        fullscreenButton.addEventListener("click", (e) => {
            document
                .querySelector(".panel-nonvr--default")
                .classList.toggle("panel-nonvr--fullscreen");
        });
    }
}

function bindBlobsControllerEvents() {
    controller1.addEventListener("selectend", onControllerClick);
    controller2.addEventListener("selectend", onControllerClick);
}

function tryOpenDefaultDough() {
    if (window.defaultDoughId) {
        var defaultDough = state.getAllDoughs().find((x) => x.id == window.defaultDoughId);
        var blob = defaultDough.blob;
        if (!blob) {
            throw new Error("No blob found on the dough meta.");
        }
        state.setOpenDoughs(state.getContinentDoughs(defaultDough.continent));
        openBlobAndShowUI(blob).then(() => {
            blobToRotate = blob;
        });
        return true;
    }
    return false;
}

function onMouseDown(e) {
    if (!state.getCurrentBlob()) return;
    if (state.getVrActive()) return;

    previousMousePosition = {
        x: e.offsetX,
        y: e.offsetY,
    };

    isDragging = true;
}

function onTouchStart(evt) {
    if (!state.getCurrentBlob()) return;
    if (evt.touches.length !== 1) {
        return;
    }

    previousMousePosition = {
        x: evt.touches[0].pageX,
        y: evt.touches[0].pageY,
    };

    isDragging = true;
}

function destroyBlob(blob) {
    scene.remove(blob);
    blob.traverse(function (child) {
        if (child.isMesh) {
            child.geometry.dispose();
        }
    });
    blob.material.dispose();
}

function rotate(deltaMove, deltaMoveY) {
    var deltaRotationQuaternion = new THREE.Quaternion().setFromEuler(
        new THREE.Euler(
            0,
            THREE.MathUtils.degToRad(deltaMove * 0.4),
            THREE.MathUtils.degToRad(deltaMoveY * 0.4),
            "XYZ"
        )
    );

    blobToRotate.quaternion.multiplyQuaternions(deltaRotationQuaternion, blobToRotate.quaternion);
}

function onMouseMove(e) {
    if (!isDragging) return;

    var deltaMove = e.clientX - previousMousePosition.x;
    var deltaMoveY = e.clientY - previousMousePosition.y;

    rotate(deltaMove, deltaMoveY);

    previousMousePosition.x = e.clientX;
    previousMousePosition.y = e.clientY;
}
function onTouchMove(evt) {
    if (!isDragging) return;

    var deltaMove = evt.touches[0].pageX - previousMousePosition.x;
    var deltaMoveY = evt.touches[0].pageY - previousMousePosition.y;

    rotate(deltaMove, deltaMoveY);

    previousMousePosition.x = evt.touches[0].pageX;
    previousMousePosition.y = evt.touches[0].pageY;
}

function onMouseUp(e) {
    if (state.getVrActive()) return;

    isDragging = false;
}
function onTouchEnd(e) {
    isDragging = false;
}

function checkBlobs(mode, raycast) {
    if (blobToRotate) return false;

    var intersects = raycast.intersectObjects(blobs);

    if (intersects.length) {
        if (!blobToHover) blobToHover = intersects[0].object;
        showPointerCursorDefined("blobDough");
        return true;
    } else {
        intersection = null;
        blobToHover = null;
    }

    resetIsOn();

    return false;
}

function startOpeningBlob(intersection) {
    if (state.getUIOpened() || isOpeningBlob) return;
    isOpeningBlob = true;

    var blob = intersection.object;
    blobToRotate = blob;

    state.setOpenDoughs(state.getContinentDoughs(blob.meta.dough.continent));
    openBlobAndShowUI(blob).then((blob) => {
        isOpeningBlob = false;
    });
}

function testControllerRayCast(controller) {
    tempMatrix.identity().extractRotation(controller.matrixWorld);

    raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
    raycaster.ray.direction.set(0, 0, -1).applyMatrix4(tempMatrix);

    // calculate objects intersecting the picking ray
    var intersects = raycaster.intersectObjects(blobs);

    if (intersects.length) {
        intersection = intersects[0];
    } else {
        intersection = null;
    }

    if (!intersection) return;

    console.log("OPEN VR UI");

    if (state.getVrActive()) {
        startOpeningBlobVr(intersection);
    } else {
        startOpeningBlob(intersection);
    }
}

function hideVrBlobUiIfOpen(immediate) {
    if (state.getCurrentBlob() != null) {
        hideVrBlobScreen(immediate);
        moveBlobBack(state.getCurrentBlob());
    }
}

function startOpeningBlobVr(intersection) {
    var blob = intersection.object;

    hideVrBlobUiIfOpen();
    hideVrArtifactUiIfOpen();

    blobToRotate = blob;

    var marker = blob.meta.fridge.marker;

    showVrBlobScreen(blob.meta);

    console.log("after split VR");

    blob.matrixAutoUpdate = true;
    moveBlob(blob, marker, true); // don't wait for the move to be complete

    console.log("after move blob VR");

    state.setCurrentBlob(blob);
    console.log("SET current  blob VR", blob);

    //state.setUIOpened(true);
}

function onControllerClick(event) {
    if (!state.getVrActive()) return;

    testControllerRayCast(controller1);
    testControllerRayCast(controller2);
}

function onMouseClick(event) {
    var t = performance.now() - window.lastLookedAround;
    if (t < 300) {
        // just looked around. do nothing
        return;
    }

    // already a UI open, don't try to open another time
    if (state.getCurrentBlob()) return;

    // normalized mouse coords
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    raycaster.setFromCamera(mouse, camera);

    // calculate objects intersecting the picking ray
    var intersects = raycaster.intersectObjects(blobs);

    if (intersects.length) {
        intersection = intersects[0];
    } else {
        intersection = null;
    }

    if (!intersection) return;

    startOpeningBlob(intersection);
}

async function addBlob(dough, fridge) {
    if (!dough) {
        throw Error("Dough should be provided");
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    // ToDo: Check lightning conditions and maybe check with a LightProbe too
    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    var perfParams = getPerformanceParameters();

    var hdrUris;
    if (fridge.kind === "left")
        hdrUris = [
            "/envmaps/fridge-left/fridge02_posx.hdr",
            "/envmaps/fridge-left/fridge02_negx.hdr",
            "/envmaps/fridge-left/fridge02_posy.hdr",
            "/envmaps/fridge-left/fridge02_negy.hdr",
            "/envmaps/fridge-left/fridge02_posz.hdr",
            "/envmaps/fridge-left/fridge02_negz.hdr",
        ];
    else
        hdrUris = [
            "/envmaps/fridge-right/fridge01_posx.hdr",
            "/envmaps/fridge-right/fridge01_negx.hdr",
            "/envmaps/fridge-right/fridge01_posy.hdr",
            "/envmaps/fridge-right/fridge01_negy.hdr",
            "/envmaps/fridge-right/fridge01_posz.hdr",
            "/envmaps/fridge-right/fridge01_negz.hdr",
        ];

    var texture = dough.texture;
    if (!texture) {
        console.error("Texture not found with ID: " + dough.textureId);
        return;
    }

    const [baseTexture, heightTexture, normalTexture, roughnessTexture] = getBlobTextures(texture);

    const texturesToLoad = [
        loadOBJ(texture.blob),
        loadTexture(baseTexture),
        loadTexture(normalTexture),
        loadHDRCubeTexture(renderer, hdrUris),
    ];
    //if (perfParams.alltextures) {
    texturesToLoad.push(loadTexture(heightTexture));
    texturesToLoad.push(loadTexture(roughnessTexture));
    //}
    const [mesh, baseMap, normalMap, envMap, displacementMap, roughnessMap] = await Promise.all(
        texturesToLoad
    );

    var ua = navigator.userAgent;
    //console.log( "UserAgent:" );
    //console.log( ua );

    var materialOptions = {
        color: 0xffffff,
        map: baseMap,
        displacementScale: 0.04,
        normalMap: normalMap,
        normalScale: new THREE.Vector2(dough.normalScale, dough.normalScale),
        transparent: true,
        side: THREE.DoubleSide,
    };

    materialOptions.displacementMap = displacementMap;
    materialOptions.displacementMap.anisotropy = 16;
    materialOptions.roughnessMap = roughnessMap;

    if (perfParams.envmaps) {
        materialOptions.envMap = envMap;
        materialOptions.envMap.anisotropy = 16;
        materialOptions.envMapIntensity = dough.envmapIntensity;
    }

    var defines = "";
    // if (perfParams.alltextures) {
    //     //defines += "#define doDisplace\n";
    // }

    // if (parseInt(dough.agressiveness) === 0 || parseInt(dough.amplitude) === 0) {
    //     //defines = "";
    //     //materialOptions.displacementMap   = null;
    //     //materialOptions.displacementScale = 0.03;
    // }

    var blobMesh = mesh.children[0];

    var material = new THREE.MeshStandardMaterial(materialOptions);

    //var displacement = new Float32Array(blobMesh.geometry.attributes.position.count);
    var displace = [];

    for (var i = 0; i < blobMesh.geometry.attributes.position.array.length; i += 3) {
        displace.push(
            blobMesh.geometry.attributes.position.array[i],
            blobMesh.geometry.attributes.position.array[i + 1],
            blobMesh.geometry.attributes.position.array[i + 2]
        );
    }

    blobMesh.geometry.setAttribute(
        "displacementValue",
        new THREE.BufferAttribute(new Float32Array(displace), 3)
    );

    var mobileAmbienceBoost = 1.25;

    if (!perfParams.alltextures) {
        mobileAmbienceBoost = 0.75;
    }

    if (
        ua.toString().toLowerCase().indexOf("oneplus", 0) != -1 ||
        ua.toString().toLowerCase().indexOf("pixel", 0) != -1 ||
        ua.toString().toLowerCase().indexOf("gm190", 0) != -1 ||
        ua.toString().toLowerCase().indexOf("hd190", 0) != -1
    ) {
        mobileAmbienceBoost = 2.25;
        material.envMapIntensity = 0.45;

        material.roughness = 2.0;
        material.metalness = 0.0;
    } else if (isOculusBrowser()) {
        mobileAmbienceBoost = 3.65;
        material.envMapIntensity = 0.45;

        material.roughness = 2.0;
        material.metalness = 0.0;
    }

    material.needsUpdate = true;

    dough.ambientLightStrength = dough.ambientLightStrength * mobileAmbienceBoost;

    console.log("AMBIENT", {
        mobileAmbienceBoost,
        ambientLightStrength: dough.ambientLightStrength,
        metalness: material.metalness,
        roughness: material.roughness,
    });

    material.onBeforeCompile = (shader) => {
        material.uniforms = shader.uniforms;
        shader.uniforms.time = { value: 0 };
        shader.uniforms.pulse = { value: 0.026 };
        shader.uniforms.amplitude = { value: 0.003 };
        shader.uniforms.ambientLightStrength = { value: dough.ambientLightStrength };

        shader.vertexShader =
            defines +
            `
            
            attribute vec3 displacementValue;

            uniform float time;
            uniform float pulse;
            uniform float amplitude;

        ${shader.vertexShader}`;
        shader.vertexShader = shader.vertexShader.replace(
            `#include <project_vertex>`,
            `
            vec3 newPos;

            transformed = position + displacementValue;
            transformed *= 0.4;

            #include <project_vertex>`
        );

        // add piece of code to top of existing shader
        shader.fragmentShader = `
            uniform float ambientLightStrength;
            vec3 gamma(vec3 value, float param)
            {
                return vec3(pow(abs(value.r), param),pow(abs(value.g), param),pow(abs(value.b), param));
            }
            vec3 contrast(vec3 color, float c)
            {
                float t = 0.5 - c * 0.5; 
                return color * c + t;
            }
        ${shader.fragmentShader}`;

        // add saturation on oculus, and use the ambientLightStrength modifier
        shader.fragmentShader = shader.fragmentShader.replace(
            "gl_FragColor = vec4( outgoingLight, diffuseColor.a );",
            `vec3 ambient = vec3( ambientLightStrength ) * diffuseColor.rgb;     
             outgoingLight.rgb += ambient.rgb;       

             ${
                 isOculusBrowser()
                     ? `
             outgoingLight.rgb = gamma(outgoingLight.rgb, 1.6);
             `
             : ""
            }
            
            //outgoingLight.rgb = contrast(outgoingLight.rgb, 3.9);
            gl_FragColor = vec4(outgoingLight, diffuseColor.a);`
        );

        return shader;
    };

    blobMesh.material = material;
    blobMesh.frustumCulled = false;
    addFrustumCullingMesh(blobMesh);

    blobMesh.name = "blob_" + dough.continent + "_" + blobCounter++;
    blobMesh.matrixAutoUpdate = false;
    var meta = {
        dough: dough,
        fridge: fridge,
    };
    dough.blob = blobMesh; // connect blob to dough meta data, so yes both directions
    blobMesh.meta = meta; // connect meta data to blob (so yes, both directions)

    randomBlobMesh = blobMesh;

    if (blobMesh.material.map) {
        blobMesh.material.map.anisotropy = 16;
        blobMesh.material.map.encoding = THREE.sRGBEncoding;
    }

    if (blobMesh.material.roughnessMap) {
        blobMesh.material.roughnessMap.anisotropy = 16;
    }

    if (blobMesh.material.normalMap) {
        blobMesh.material.normalMap.anisotropy = 16;
    }

    if (blobMesh.material.displacementMap) {
        blobMesh.material.displacementMap.anisotropy = 16;
    }

    // return the Mesh itself instead of the group
    return blobMesh;
}

function replaceBlobInRaycastingArray(oldBlob, newBlob) {
    var i = blobs.indexOf(oldBlob);
    blobs[i] = newBlob;
}

function setBlobToRotate(blob) {
    blobToRotate = blob;
}

function onBlobsRenderTick(isVr) {
    if (blobToHover) {
        blobToHover.matrixAutoUpdate = true;

        if (!isDragging) {
            var deltaRotationQuaternion = new THREE.Quaternion().setFromEuler(
                new THREE.Euler(0, THREE.MathUtils.degToRad(0.55), 0, "XYZ")
            );

            blobToHover.quaternion.multiplyQuaternions(
                deltaRotationQuaternion,
                blobToHover.quaternion
            );
        }
    }

    if (blobToRotate) {
        blobToHover = null;

        if (!isDragging) {
            var deltaRotationQuaternion = new THREE.Quaternion().setFromEuler(
                new THREE.Euler(0, THREE.MathUtils.degToRad(0.35), 0, "XYZ")
            );

            blobToRotate.quaternion.multiplyQuaternions(
                deltaRotationQuaternion,
                blobToRotate.quaternion
            );
        }

        if (getPerformanceParameters().blobanim && !isVr) {
            //change '0.003' for more aggressive animation
            var time = performance.now() * blobToRotate.meta.dough.agressiveness;

            var attributes = blobToRotate.geometry.attributes;
            var k = 2;

            for (var i = 0; i < attributes.displacementValue.array.length; i += 3) {
                var p = new THREE.Vector3(
                    attributes.displacementValue.array[i],
                    attributes.displacementValue.array[i + 1],
                    attributes.displacementValue.array[i + 2]
                );
                p.normalize().multiplyScalar(
                    0.1 +
                        blobToRotate.meta.dough.amplitude *
                            noise.perlin3(p.x * k + time, p.y * k, p.z * k)
                );
                attributes.displacementValue.array[i] = p.x;
                attributes.displacementValue.array[i + 1] = p.y;
                attributes.displacementValue.array[i + 2] = p.z;
            }

            attributes.displacementValue.needsUpdate = true;
        }
    }
}

function getRandomBlobMesh() {
    return randomBlobMesh;
}

export {
    addBlob,
    initializeBlobs,
    onBlobsRenderTick,
    tryOpenDefaultDough,
    destroyBlob,
    replaceBlobInRaycastingArray,
    setBlobToRotate,
    gatherVisibleDoughs,
    checkBlobs,
    bindBlobsControllerEvents,
    getRandomBlobMesh,
    hideVrBlobUiIfOpen,
};
