function hasClickedElement(panel, element, x, y) {
    panel.style.display = "block";

    var bounding = element.getBoundingClientRect();

    var relativePos = {
        left: element.offsetLeft,
        right: element.offsetLeft + bounding.width,
        top: element.offsetTop,
        bottom: element.offsetTop + bounding.height,
    };

    panel.style.display = "none";

    if (
        x > relativePos.left &&
        x < relativePos.right &&
        y > relativePos.top &&
        y < relativePos.bottom
    ) {
        // clicked
        return true;
    }
    return false;
}

function convertLocalCoordsToPixels(screen, screenWidth, screenHeight, intersection) {
    // calculate coords of the point clicked in HTML/CSS space
    var localCoords = screen.worldToLocal(intersection.point);
    var x = ((localCoords.x * 2 + 1) / 2) * screenWidth;
    var y = ((-(localCoords.y * 2) + 1) / 2) * screenHeight;

    return { x: x, y: y };
}

export { hasClickedElement, convertLocalCoordsToPixels };
