import * as THREE from "three";
import { composeAssetUrl } from "./asyncLoaders";

var soundStarted = false;

function initializeSound(camera) {
    if (soundStarted) return;

    // create an AudioListener and add it to the camera
    var listener = new THREE.AudioListener();
    camera.add(listener);

    // create a global audio source
    var sound = new THREE.Audio(listener);

    // load a sound and set it as the Audio object's buffer
    var audioLoader = new THREE.AudioLoader();
    var url = composeAssetUrl("/sound/background.mp3");
    audioLoader.load(url, function (buffer) {
        sound.setBuffer(buffer);
        sound.setLoop(true);
        sound.setVolume(0.2);
        sound.play();

        soundStarted = true;
    });
    
    var audioButton = document.getElementById("audio-toggle");
    audioButton.addEventListener("click", function () {
        if (sound.isPlaying) {
            audioButton.classList.remove("playing");
            sound.pause();
        } else {
            audioButton.classList.add("playing");
            sound.play();
        }
    });
}

export { initializeSound };
