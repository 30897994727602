var canvas, previousCursor;
var isOn = "none";

function showGrabbingCursor() {
    if (!canvas) return;
    previousCursor = canvas.style.cursor;
    canvas.style.cursor = "grabbing";
}

function showGrabCursor() {
    if (!canvas) return;
    previousCursor = canvas.style.cursor;
    canvas.style.cursor = "grab";
}

function showPointerCursor() {
    if (!canvas) return;
    previousCursor = canvas.style.cursor;
    canvas.style.cursor = "pointer";
}

function showPointerCursorDefined(value) {
    if (!canvas) return;
    previousCursor = canvas.style.cursor;
    canvas.style.cursor = "pointer";
    isOn = value;
}

function resetIsOn() {
    isOn = "none";
}

function restoreCursor() {
    if (!canvas) return;
    canvas.style.cursor = previousCursor;
}

function initializeCursors(renderer) {
    canvas = renderer.domElement;
    showPointerCursor();
}

function isGrabbing() {
    if (!canvas) return;
    return canvas.style.cursor == "grabbing";
}

export {
    showGrabbingCursor,
    showGrabCursor,
    showPointerCursor,
    showPointerCursorDefined,
    initializeCursors,
    restoreCursor,
    isGrabbing,
    resetIsOn,
    isOn,
};
