import { state } from "../common/state";
import { fetchDoughAndShow } from "./ui-nonvr";
import tippy from "tippy.js";

var toggler, menu, logo, initialized, errorTip;

function closeNav() {
    toggler.classList.remove("open");
    menu.classList.remove("open");
    logo.classList.remove("menu-opened");

    state.setUIOpened(false);
}

function openNav() {
    if (state.getUIOpened()) return;

    toggler.classList.add("open");
    menu.classList.add("open");
    logo.classList.add("menu-opened");

    state.setUIOpened(true);
}

function showHamburgerNavButton() {
    if (toggler) toggler.classList.add("active");
}

function hideHamburgerNavButton() {
    if (toggler) toggler.classList.remove("active");
}

function initLanguageSwitcher() {
    var switcher = document.getElementById("language");
    switcher.addEventListener("change", function () {
        var url = switcher.options[switcher.selectedIndex].getAttribute("data-url");
        window.location.href = url;
    });
}

function initializeHamburgerNav(scene) {
    if (initialized) return;

    initLanguageSwitcher();

    toggler = document.querySelector(".mobile-nav__toggler");
    logo = document.querySelector(".main-logo-puratos");

    if (!toggler) return;

    var close = document.querySelector(".mobile-nav__close");
    menu = document.getElementById("panel-mobile-nav");

    toggler.addEventListener("click", function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        openNav();
    });

    close.addEventListener("click", function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        closeNav();
    });

    Array.from(document.querySelectorAll(".button-infowindow")).forEach(function (elmnt) {
        elmnt.addEventListener("click", function (e) {
            e.preventDefault();
            document.getElementById("panel-infowindow").classList.add("open");
            hideHamburgerNavButton();
            closeNav();
        });
    });

    document.getElementById("button-close-infowindow").addEventListener("click", function (e) {
        e.preventDefault();
        document.getElementById("panel-infowindow").classList.remove("open");
        showHamburgerNavButton();
        openNav();
    });

    // search by number

    Array.from(document.querySelectorAll(".search-number-form")).forEach(function (searchForm) {
        var searchInput = searchForm.querySelector("input");
        searchInput.addEventListener("keydown", function () {
            if (errorTip) errorTip.destroy();
            searchInput.classList.remove("has-error");
        });
        searchForm.addEventListener("submit", function (e) {
            e.preventDefault();
            var val = searchInput.value;
            console.log("YES", val);

            var dough = state.getAllDoughs().find((x) => x.number == val);
            if (!dough) {
                errorTip = tippy(searchInput, {
                    showOnCreate: true,
                    theme: "error",
                    content(ref) {
                        const title = ref.getAttribute("data-error");
                        return title;
                    },
                    placement: "top-start",
                    offset: [0, 10],
                });
                searchInput.classList.add("has-error");
                return;
            }

            if (errorTip) errorTip.destroy();
            searchInput.classList.remove("has-error");
            searchInput.value = "";
            setTimeout(() => {
                fetchDoughAndShow(dough);
                closeNav();
            });
        });
    });
}

export { initializeHamburgerNav, showHamburgerNavButton, hideHamburgerNavButton };
