import request from "../common/superagent";
import html2canvas from "html2canvas";
import TWEEN from "@tweenjs/tween.js";
import { composeTagArray } from "./change-ui-values";
import { state } from "../common/state";
import { controller1, controller2 } from "../vr-controller";
import { endVrSession } from "../vr-button";
import { closeUI } from "./ui-nonvr";
import { convertLocalCoordsToPixels, hasClickedElement } from "./ui-vr-common";

const screenWidth = 400; // pixels, should be the same as CSS size of the HTML element

var texture, screen, screenArray, raycaster, material, mouse, renderer, camera, screenHeight;
var tempMatrix = new THREE.Matrix4();

async function changeUiValuesVr(meta) {
    var obj = document.getElementById("panel-vr-ui");
    var res = await request
        .get(`${window.baseApiUrl}/api/sourdoughs/${meta.id}`)
        .query({ dt: Date.now() });
    var dto = res.body;

    obj.querySelector(".property-title").innerHTML = meta.title;
    obj.querySelector(".property-country").innerHTML = dto.country;
    obj.querySelector(".property-description").innerHTML = dto.description;

    obj.querySelector(".property-flourtype").innerHTML = dto.flourType.join(", ");
    obj.querySelector(".property-bakedgoods").innerHTML = dto.bakedGoods;

    if (dto.bakedGoods) obj.querySelector(".property-bakedgoods-panel").style.display = "flex";
    else obj.querySelector(".property-bakedgoods-panel").style.display = "none";

    if (dto.flourType.length) obj.querySelector(".property-flourtype-panel").style.display = "flex";
    else obj.querySelector(".property-flourtype-panel").style.display = "none";

    if (dto.owner) obj.querySelector(".property-owner-panel").style.display = "flex";
    else obj.querySelector(".property-owner-panel").style.display = "none";

    obj.querySelector(".property-owner").innerHTML = dto.owner;

    if (dto.yeastSpecies.length) {
        obj.querySelector(".property-yeastSpecies-panel").style.display = "flex";
    } else {
        obj.querySelector(".property-yeastSpecies-panel").style.display = "none";
        obj.querySelector(".property-yeastSpecies-panel").classList.remove("block-line");
    }

    if (dto.lacticAcidSpecies.length)
        obj.querySelector(".property-lacticAcidSpecies-panel").style.display = "flex";
    else obj.querySelector(".property-lacticAcidSpecies-panel").style.display = "none";

    // hide complete mibrobial panel
    if (!dto.yeastSpecies.length && !dto.lacticAcidSpecies.length)
        obj.querySelector(".property-microbial-panel").style.display = "none";
    else obj.querySelector(".property-microbial-panel").style.display = "block";

    obj.querySelector(".property-yeastSpecies").innerHTML = composeTagArray(dto.yeastSpecies);
    obj.querySelector(".property-lacticAcidSpecies").innerHTML = composeTagArray(
        dto.lacticAcidSpecies
    );

    if (dto.image) {
        obj.querySelector(".property-image").style.display = "block";
        obj.querySelector(".property-image .img").setAttribute("src", dto.image);
    } else {
        obj.querySelector(".property-image").style.display = "none";
        obj.querySelector(".property-image .img").setAttribute("src", "");
    }

    if (dto.videoLink) {
        obj.querySelector(".property-video").style.display = "block";
        obj.querySelector(".property-video .video").setAttribute("href", dto.videoLink);
    } else {
        obj.querySelector(".property-video").style.display = "none";
        obj.querySelector(".property-video .video").setAttribute("href", "");
    }
}

async function createTexture() {
    var elmnt = document.getElementById("panel-vr-ui");
    elmnt.style.display = "block";

    var canvas = await html2canvas(elmnt, {
        useCORS: true,
        backgroundColor: null,
        logging: true,
        scale: 2, // increase PPI
        width: screenWidth,
    });
    // derive height from the calculated canvas height
    screenHeight = parseInt(canvas.style.height.substr(0, canvas.style.height.indexOf("px")));
    // debugging the canvas
    // canvas.style.left = "0px";
    // canvas.style.top = "0px";
    // canvas.style.position = "absolute";
    // document.body.appendChild(canvas);

    texture = new THREE.CanvasTexture(canvas);
    texture.minFilter = THREE.LinearFilter;
    texture.wrapS = THREE.ClampToEdgeWrapping;
    texture.wrapT = THREE.ClampToEdgeWrapping;

    elmnt.style.display = "none";

    return texture;
}

function showVrBlobScreen(meta) {
    // positions creen

    var pos = new THREE.Vector3();
    pos.copy(meta.fridge.position);
    pos.x += meta.fridge.kind == "right" ? -0.4 : 0.4;
    pos.y -= 0.9;

    if (meta.dough.continent === "europe" || meta.dough.continent === "south-america")
        pos.z += meta.fridge.kind == "right" ? -1.1 : 1.1;
    else pos.z += meta.fridge.kind == "right" ? 1.1 : -1.1;

    screen.position.copy(pos);
    var rot =
        meta.fridge.r_y -
        (meta.dough.continent === "europe" || meta.dough.continent === "south-america" ? -18 : 18);

    screen.rotation.set(0, THREE.MathUtils.degToRad(rot), 0);
    screen.updateMatrix();

    changeUiValuesVr(meta.dough).then(() => {
        createTexture().then((texture) => {
            material.map = texture;
            material.needsUpdate = true;

            //var screen = state.getHtmlScreen();
            // debugger;
            var heightScale = screenHeight / screenWidth;
            screen.scale.set(1, heightScale, 1);

            screen.material.opacity = 0;
            screen.visible = true;

            var y = screen.position.y + 0.4;
            setTimeout(() => {
                new TWEEN.Tween(screen.position)
                    .to({ y: y })
                    .easing(TWEEN.Easing.Quadratic.Out)
                    .duration(800)
                    .start();
                new TWEEN.Tween(screen.material)
                    .to({ opacity: 1 })
                    .easing(TWEEN.Easing.Quadratic.Out)
                    .duration(800)
                    .start();
            });
        });
    });
}

function hideVrBlobScreen(immediate) {
    if (immediate) {
        screen.visible = false;
        return;
    }

    var y = screen.position.y - 0.4;
    new TWEEN.Tween(screen.position)
        .to({ y: y })
        .easing(TWEEN.Easing.Quadratic.Out)
        .duration(800)
        .start();
    new TWEEN.Tween(screen.material)
        .to({ opacity: 0 })
        .easing(TWEEN.Easing.Quadratic.Out)
        .duration(800)
        .onComplete(() => {
            screen.visible = false;
        })
        .start();
}

async function embedHtml() {
    var scene = state.getScene();

    var texture = await createTexture();
    material = new THREE.MeshBasicMaterial({
        map: texture,
        flatShading: true,
        transparent: true,
        toneMapped: false,
    });

    var geometry = new THREE.PlaneGeometry(1, 1);
    screen = new THREE.Mesh(geometry, material);
    screenArray = [screen];
    //screen.scale.set(width, height, 1);
    screen.name = "screen-blobs";

    screen.position.set(0, 1.6, 1);
    screen.visible = false;
    scene.add(screen);

    //state.setHtmlScreen(group);

    return screen;
}

function testControllerRayCast(controller) {
    tempMatrix.identity().extractRotation(controller.matrixWorld);

    raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
    raycaster.ray.direction.set(0, 0, -1).applyMatrix4(tempMatrix);

    // calculate objects intersecting the picking ray
    var intersects = raycaster.intersectObjects(screenArray);

    var intersection = null;
    if (intersects.length) {
        intersection = intersects[0];
    }

    testIntersection(intersection);
}

function onControllerClick(event) {
    if (!state.getVrActive()) return;

    testControllerRayCast(controller1);
    testControllerRayCast(controller2);
}

function onMouseClick() {
    // normalized mouse coords
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    raycaster.setFromCamera(mouse, camera);

    // calculate objects intersecting the picking ray
    var intersects = raycaster.intersectObjects(screenArray);

    var intersection = null;
    if (intersects.length) {
        intersection = intersects[0];
    }

    testIntersection(intersection);
}

function testIntersection(intersection) {
    if (!intersection) return;

    // calculate coords of the point clicked in HTML/CSS space
    var { x, y } = {
        ...convertLocalCoordsToPixels(screen, screenWidth, screenHeight, intersection),
    };

    // check if we have clicked something

    var videoEl = document.getElementById("vr-link-video");
    var panel = document.getElementById("panel-vr-ui");
    var closeEl = document.getElementById("vr-close-button");

    if (hasClickedElement(panel, videoEl, x, y)) {
        window.open(videoEl.getAttribute("href"), "_blank");
        endVrSession();
    } else if (hasClickedElement(panel, closeEl, x, y)) {
        closeUI();
    } else {
        var elements = Array.from(panel.querySelectorAll(".property-description a"));
        elements.forEach((elmnt) => {
            if (hasClickedElement(panel, elmnt, x, y)) {
                window.open(elmnt.getAttribute("href"), "_blank");
                endVrSession();
            }
        });
    }
}

function initializeUiVr() {
    console.log("Initlialize VR UI");

    var res = embedHtml();

    raycaster = new THREE.Raycaster();
    mouse = new THREE.Vector2();

    // bind events

    camera = state.getCamera();
    renderer = state.getRenderer();
    var domEl = renderer.domElement;
    domEl.addEventListener("click", onMouseClick);

    controller1.addEventListener("selectend", onControllerClick);
    controller2.addEventListener("selectend", onControllerClick);

    return res;
}

export { initializeUiVr, showVrBlobScreen, hideVrBlobScreen };
