import * as THREE from "three";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { HDRCubeTextureLoader } from "three/examples/jsm/loaders/HDRCubeTextureLoader.js";
import { composeAssetUrl } from "./asyncLoaders";
import { getPerformanceParameters, getBlobTextures } from "./performance";
import { state } from "./state";

var manager;
var percentLoaded = 0;

class PreloadingManager {
    constructor() {}

    static getManager() {
        return manager;
    }

    initialize(onLoad, onProgress) {
        THREE.Cache.enabled = true;
        manager = new THREE.LoadingManager();
        manager.onLoad = onLoad;
        manager.onProgress = onProgress;
        this.textures = [];
        this.rgbes = [];
        this.objs = [];
        this.cubeTextures = [];
        this.gltfs = [];
        this.files = [];
    }

    addTexture(uri) {
        var url = composeAssetUrl(uri);
        this.textures.push(url);
    }

    addRGBE(uri) {
        var url = composeAssetUrl(uri);
        this.rgbes.push(url);
    }

    addHDRCubeTexture(hdrUris) {
        var urls = hdrUris.map((x) => composeAssetUrl(x));
        this.cubeTextures.push(urls);
    }

    addOBJ(uri) {
        var url = composeAssetUrl(uri);
        this.objs.push(url);
    }

    addGLTF(uri) {
        var url = composeAssetUrl(uri);
        this.gltfs.push(url);
    }

    addFile(uri) {
        var url = composeAssetUrl(uri);
        this.files.push(url);
    }

    start() {
        for (let item of this.textures) {
            new THREE.TextureLoader(manager).load(item);
        }
        for (let item of this.rgbes) {
            new RGBELoader(manager).load(item);
        }
        for (let item of this.cubeTextures) {
            new HDRCubeTextureLoader(manager).setDataType(THREE.UnsignedByteType).load(item);
        }
        for (let item of this.objs) {
            new OBJLoader(manager).load(item, () => {});
        }
        for (let item of this.gltfs) {
            new GLTFLoader(manager).load(item, () => {});
        }
        for (let item of this.files) {
            new THREE.FileLoader(manager).load(item);
        }
    }
}

async function preloadAll() {
    return new Promise((resolve) => {
        var mgr = new PreloadingManager();
        mgr.initialize(
            () => {
                resolve();
            },
            (url, itemsLoaded, itemsTotal) => {
                var percent = (itemsLoaded / itemsTotal) * 100;
                var percent = Math.min(percent, 99);
                if (percent > percentLoaded) {
                    percentLoaded = percent;
                    document.getElementById("percent").innerHTML = Math.round(percent) + "%";
                }
            }
        );
        mgr.addGLTF("/museum/museum.gltf");
        if (getPerformanceParameters().envmaps) {
            mgr.addHDRCubeTexture([
                "/envmaps/fridge-left/fridge02_posx.hdr",
                "/envmaps/fridge-left/fridge02_negx.hdr",
                "/envmaps/fridge-left/fridge02_posy.hdr",
                "/envmaps/fridge-left/fridge02_negy.hdr",
                "/envmaps/fridge-left/fridge02_posz.hdr",
                "/envmaps/fridge-left/fridge02_negz.hdr",
            ]);
            mgr.addHDRCubeTexture([
                "/envmaps/fridge-right/fridge01_posx.hdr",
                "/envmaps/fridge-right/fridge01_negx.hdr",
                "/envmaps/fridge-right/fridge01_posy.hdr",
                "/envmaps/fridge-right/fridge01_negy.hdr",
                "/envmaps/fridge-right/fridge01_posz.hdr",
                "/envmaps/fridge-right/fridge01_negz.hdr",
            ]);
            mgr.addHDRCubeTexture([
                "/envmaps/museum_posx.hdr",
                "/envmaps/museum_negx.hdr",
                "/envmaps/museum_posy.hdr",
                "/envmaps/museum_negy.hdr",
                "/envmaps/museum_posz.hdr",
                "/envmaps/museum_negz.hdr",
            ]);
            mgr.addHDRCubeTexture([
                "/envmaps/pedestals-left/item01_posx.hdr",
                "/envmaps/pedestals-left/item01_negx.hdr",
                "/envmaps/pedestals-left/item01_posy.hdr",
                "/envmaps/pedestals-left/item01_negy.hdr",
                "/envmaps/pedestals-left/item01_posz.hdr",
                "/envmaps/pedestals-left/item01_negz.hdr",
            ]);
            mgr.addHDRCubeTexture([
                "/envmaps/pedestals-right/item02_posx.hdr",
                "/envmaps/pedestals-right/item02_negx.hdr",
                "/envmaps/pedestals-right/item02_posy.hdr",
                "/envmaps/pedestals-right/item02_negy.hdr",
                "/envmaps/pedestals-right/item02_posz.hdr",
                "/envmaps/pedestals-right/item02_negz.hdr",
            ]);
        }
        mgr.addRGBE("/sky.hdr");
        mgr.addRGBE("/lightmaps/lightmap_museum.hdr");
        mgr.addRGBE("/lightmaps/lightmap_trees.hdr");
        mgr.addRGBE("/lightmaps/lightmap_vines.hdr");
        mgr.addOBJ("/navmesh.obj");

        mgr.addGLTF("/certificate/certificate.gltf");
        mgr.addGLTF("/bread/bread.gltf");
        mgr.addGLTF("/microscope/microscope.gltf");
        mgr.addGLTF("/tubes/tubes.gltf");
        mgr.addGLTF("/jar/jar.gltf");
        mgr.addGLTF("/stamp/stamp.gltf");

        var visibleDoughs = state.getAllVisibleContinentDoughs();
        for (var continent in visibleDoughs) {
            var doughs = visibleDoughs[continent];
            for (var dough of doughs) {
                var texture = dough.texture;
                mgr.addOBJ(texture.blob);
                const [
                    baseTexture,
                    heightTexture,
                    normalTexture,
                    roughnessTexture,
                ] = getBlobTextures(texture);
                mgr.addTexture(baseTexture);
                mgr.addTexture(heightTexture);
                mgr.addTexture(normalTexture);
                mgr.addTexture(roughnessTexture);
            }
        }

        mgr.start();
    });
}

export { PreloadingManager, preloadAll };
