import { fridgePositions } from "../common/fridge-positions";
import { openBlobAndShowUI, openCountryAndShowUI } from "../ui/ui-nonvr";
import request from "../common/superagent";
import { state } from "./state";
import { switchBlobs } from "../ui/ui-nonvr";

var wrapper, flourId, countryId, continent;

function hideBlobNav() {
    wrapper = document.getElementById("blob-nav");
    wrapper.classList.remove("button-nav--open");

    document.getElementById("next-button-nonvr").classList.remove("active");
    document.getElementById("prev-button-nonvr").classList.remove("active");

    setSelectedValueCountry();
    setSelectedValueFlourtype();
}

function loadDoughs() {
    request
        .get(window.baseApiUrl + "/api/doughs-per-flourtype-country")
        .query({ continent: continent, flourTypeId: flourId, countryId: countryId })
        .then((res) => {
            var currentDoughId = state.getCurrentBlob().meta.dough.id;
            // find doughs based on the ID of search result
            var doughs = [];
            for (var item of res.body) {
                var tmp = state.getAllDoughs().find((x) => x.id == item.id);
                doughs.push(tmp);
            }

            // if the blob we are watching now is in the result set, don't switch blobs

            state.setOpenDoughs(doughs);
            if (doughs.length && currentDoughId != doughs[0].id) {
                // the blob which is opened is not the same as the first of the ones we're displaying. So fully switch
                switchBlobs(() => 0);
            }
        });
}

function onFlourtypeClick(e) {
    var elmnt = e.currentTarget;
    flourId = elmnt.getAttribute("data-id");
    setSelectedValueFlourtype(elmnt.innerHTML);

    loadDoughs();
    loadCountries();
}

function onCountryClick(e) {
    var elmnt = e.currentTarget;
    countryId = elmnt.getAttribute("data-id");
    setSelectedValueCountry(elmnt.innerHTML);

    loadDoughs();
    loadFlourTypes();
}

function loadFlourTypes() {
    emptyFlourTypes();
    request
        .get(window.baseApiUrl + "/api/continent-flourtypes")
        .query({ continent: continent, countryId: countryId })
        .then((res) => {
            setFlourTypes(res.body);
        });
}

function loadCountries() {
    emptyCountries();
    request
        .get(window.baseApiUrl + "/api/continent-countries")
        .query({ continent: continent, flourTypeId: flourId })
        .then((res) => {
            setCountries(res.body);
        });
}

function emptyCountries() {
    var countryList = document.getElementById("fridge-country-list");
    countryList.innerHTML = "";
}

function setCountries(countries) {
    var countryList = document.getElementById("fridge-country-list");
    for (var item of countries) {
        var li = document.createElement("li");
        var a = document.createElement("a");
        a.setAttribute("class", "country-select");
        a.setAttribute("data-id", item.id);
        a.innerHTML = item.title;
        a.addEventListener("click", onCountryClick);
        li.appendChild(a);
        countryList.appendChild(li);
    }
}

function emptyFlourTypes() {
    var flourList = document.getElementById("fridge-flourtype-list");
    flourList.innerHTML = "";
}

function setFlourTypes(flourTypes) {
    var flourList = document.getElementById("fridge-flourtype-list");
    for (var item of flourTypes) {
        var li = document.createElement("li");
        var a = document.createElement("a");
        a.setAttribute("class", "flourtype-select");
        a.setAttribute("data-id", item.id);
        a.innerHTML = item.title;
        a.addEventListener("click", onFlourtypeClick);
        li.appendChild(a);
        flourList.appendChild(li);
    }
}

function showBlobNav(continentSlug) {
    continent = continentSlug;
    wrapper = document.getElementById("blob-nav");
    wrapper.classList.add("button-nav--open");

    if (state.getOpenDoughs().length > 1) {
        document.getElementById("next-button-nonvr").classList.add("active");
        document.getElementById("prev-button-nonvr").classList.add("active");
    } else {
        document.getElementById("next-button-nonvr").classList.remove("active");
        document.getElementById("prev-button-nonvr").classList.remove("active");
    }

    // replace the countries and flour types in the dropups with the data from the continent
    loadCountries();
    loadFlourTypes();
}

function initializeBlobNav() {
    document.getElementById("btn-clear-flourtype").addEventListener("click", function (e) {
        e.stopPropagation(); // prevent popup from opening
        e.preventDefault();

        flourId = null;
        setSelectedValueFlourtype();
        loadCountries();
        loadDoughs();
    });
    document.getElementById("btn-clear-country").addEventListener("click", function (e) {
        e.stopPropagation(); // prevent popup from opening
        e.preventDefault();

        countryId = null;
        setSelectedValueCountry();
        loadFlourTypes();
        loadDoughs();
    });
}

function setSelectedValueCountry(value) {
    var el = document.querySelector(".button-dropup--country .button-dropup__placeholder");

    if (value && !el.getAttribute("data-original-text")) {
        el.setAttribute("data-original-text", el.innerHTML);
    }
    if (value) el.innerHTML = value;
    else if (el.getAttribute("data-original-text"))
        el.innerHTML = el.getAttribute("data-original-text");
}

function setSelectedValueFlourtype(value) {
    var el = document.querySelector(".button-dropup--flourtype .button-dropup__placeholder");

    if (value && !el.getAttribute("data-original-text")) {
        el.setAttribute("data-original-text", el.innerHTML);
    }
    if (value) el.innerHTML = value;
    else if (el.getAttribute("data-original-text"))
        el.innerHTML = el.getAttribute("data-original-text");
}

export {
    hideBlobNav,
    showBlobNav,
    setSelectedValueFlourtype,
    setSelectedValueCountry,
    initializeBlobNav,
};
